import { buildFormData, parseResBody } from '../utils'
import ThrottledError from './error/ThrottledError'
import paths from './paths'
import buildReqHeaders from './utils/buildReqHeaders'

const registerMfaPhoneNumber = async ({ phoneNumber, parentAppId }) => {
  const body = buildFormData({
    phoneNumber,
  })

  const res = await fetch(paths.registerMfaPhoneNumber(), {
    method: 'POST',
    body,
    headers: buildReqHeaders({ parentAppId }),
  })

  if (res.status === 429) {
    throw new ThrottledError()
  }

  if (!res.ok) {
    throw {
      response: {
        data: await parseResBody(res),
      },
    }
  }

  return res.json()
}

export default registerMfaPhoneNumber
