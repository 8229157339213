import { NOOP } from '../../constants'
import CONSTANTS from '../../services/vtexid/constants'
import registerMfaAuthenticator from '../../services/vtexid/registerMfaAuthenticator'
import useAsyncCallback from '../../services/vtexid/utils/useAsyncCallback'
import useBeginMfaSetupAttempt from './useBeginMfaSetupAttempt'

const { API_AUTH_STATUS } = CONSTANTS

const useRegisterMfaAuthenticator = ({
  onSuccess = NOOP,
  onFailure = NOOP,
  autorun = false,
  parentAppId = null,
  actionArgs: { useNewMfaSetupAttempt: useNewMfaSetupAttemptArg = false } = {},
  mfaSetupAttempt: { onTimeout: onMfaSetupAttemptTimeout = NOOP } = {},
} = {}) => {
  const beginMfaSetupAttempt = useBeginMfaSetupAttempt(
    onMfaSetupAttemptTimeout,
    [],
    { parentAppId }
  )

  return useAsyncCallback(
    async ({ useNewMfaSetupAttempt = useNewMfaSetupAttemptArg } = {}) => {
      if (useNewMfaSetupAttempt) {
        await beginMfaSetupAttempt()
      }

      const data = await registerMfaAuthenticator({ parentAppId })
      const { authStatus } = data

      if (authStatus !== API_AUTH_STATUS.Pending) {
        throw authStatus
      }

      return data
    },
    [useNewMfaSetupAttemptArg, parentAppId, beginMfaSetupAttempt],
    { onSuccess, onFailure, autorun }
  )
}

export default useRegisterMfaAuthenticator
