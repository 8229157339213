import paths from './paths'
import buildReqHeaders from './utils/buildReqHeaders'

const startMfaSetup = async ({ parentAppId }) => {
  const res = await fetch(paths.startMfaSetup(), {
    method: 'POST',
    headers: buildReqHeaders({ parentAppId }),
  })

  if (!res.ok) {
    throw {
      response: {},
    }
  }
}

export default startMfaSetup
