import { NOOP } from '../constants'
import useRegisterMfaPhone from '../serviceHooks/vtexid/useRegisterMfaPhone'

const RegisterMfaPhone = ({ onSuccess = NOOP, onFailure = NOOP, children }) => {
  const [registerMfaPhone, { loading }] = useRegisterMfaPhone({
    onSuccess,
    onFailure,
    mfaSetupAttempt: {},
  })

  return children({
    action: registerMfaPhone,
    loading,
  })
}

export default RegisterMfaPhone
