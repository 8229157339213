import React from 'react'
import { FormattedMessage } from 'react-intl'
import APM from '../../commons/apm'

const intlErrorByCode = {
  NetworkError: <FormattedMessage id="admin/error.NetworkError" />,
}

const getIntlError = code => {
  const error = intlErrorByCode[code]

  if (error) {
    return error
  }

  // Temporary expecption capture while service clients are on react-vtexid
  APM.captureException(
    new Error(`Unexpected error occurred. Error code: ${code}`)
  )

  return <FormattedMessage id="admin/error.UnexpectedError" />
}

export default getIntlError
