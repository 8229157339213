import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import useHomeContext from '../commons/homeContext/useHomeContext'
import OAuthRedirect from '../commons/tempAuthServices/OAuthRedirect'
import LoadingWithMessage from './LoadingWithMessage'
import getIntlError from './utils/getIntlError'

const OauthRedirect = ({ setAlertError, onOauthLoginFailure }) => {
  const { email } = useHomeContext()

  const errorFallbackUrl = useMemo(
    () => window && window.location && window.location.href,
    []
  )

  return (
    <React.Fragment>
      <OAuthRedirect
        autorun
        useNewSession
        onFailure={(error) => {
          onOauthLoginFailure()
          setAlertError(getIntlError(error.code))
        }}
        provider="google"
        email={email}
        errorFallbackUrl={errorFallbackUrl}>
        {() => (
          <div data-testid="oauth-redirect">
            <LoadingWithMessage
              bottomMessage={
                <FormattedMessage
                  id="admin/providerLogin.redirectMessage"
                  values={{ provider: 'Google' }}
                />
              }
            />
          </div>
        )}
      </OAuthRedirect>
    </React.Fragment>
  )
}

export default OauthRedirect

OauthRedirect.propTypes = {
  onOauthLoginFailure: PropTypes.func.isRequired,
  setAlertError: PropTypes.func.isRequired,
}
