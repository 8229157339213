import useAsyncCallback from '../../services/vtexid/utils/useAsyncCallback'
import useBeginLoginAttempt from './useBeginLoginAttempt'
import getFingerprintIfAdmin from '../../getFingerprintIfAdmin'
import { NOOP } from '../../constants'
import CONSTANTS from '../../services/vtexid/constants'
import serviceToValidatePassword from '../../services/vtexid/validatePassword'

const { API_AUTH_STATUS } = CONSTANTS

const useLogInWithPassword = ({
  onSuccess = NOOP,
  onFailure = NOOP,
  autorun = false,
  scope = CONSTANTS.SCOPES.STORE,
  parentAppId = null,
  onRequiresSmsMfa = NOOP,
  onRequiresAppMfa = NOOP,
  onRequiresPasswordUpdate = NOOP,
  onRequiresMfaRegistration = NOOP,
  recaptchaToken: recaptchaTokenArg = null,
  actionArgs: {
    email: emailArg = null,
    password: passwordArg = null,
    recaptcha: recaptchaArg = null,
    useNewLoginAttempt: useNewLoginAttemptArg = false,
  } = {},
  loginAttempt: {
    onTimeout: onLoginAttemptTimeout = NOOP,
    returnUrl = null,
  } = {},
} = {}) => {
  const beginLoginAttempt = useBeginLoginAttempt(
    () => onLoginAttemptTimeout(),
    [onLoginAttemptTimeout],
    {
      scope,
      parentAppId,
    }
  )

  const [validatePassword, { error, loading }] = useAsyncCallback(
    async ({
      email = emailArg,
      password = passwordArg,
      recaptcha = recaptchaArg,
      recaptchaToken = recaptchaTokenArg,
      useNewLoginAttempt = useNewLoginAttemptArg,
    } = {}) => {
      const fingerprint = await getFingerprintIfAdmin(scope)

      if (useNewLoginAttempt) {
        await beginLoginAttempt({ fingerprint, email, returnUrl })
      }

      const {
        authStatus,
        phoneNumber,
        lastAttemptAvailable,
      } = await serviceToValidatePassword({
        login: email,
        password,
        recaptcha,
        recaptchaToken,
        fingerprint,
        parentAppId,
      })

      if (authStatus === API_AUTH_STATUS.Success) {
        onSuccess()

        return
      }

      if (authStatus === API_AUTH_STATUS.RequiresMFAAuthenticator) {
        onRequiresAppMfa()

        return
      }

      if (authStatus === API_AUTH_STATUS.RequiresPhoneRegistration) {
        onRequiresMfaRegistration()

        return
      }

      if (authStatus === API_AUTH_STATUS.RequiresMFA) {
        onRequiresSmsMfa({ phoneNumber })

        return
      }

      if (authStatus === API_AUTH_STATUS.ExpiredPassword) {
        onRequiresPasswordUpdate()

        return
      }

      if (authStatus === API_AUTH_STATUS.WrongCredentials) {
        throw { authStatus, lastAttemptAvailable }
      }

      throw { authStatus }
    },
    [
      emailArg,
      passwordArg,
      recaptchaArg,
      recaptchaTokenArg,
      useNewLoginAttemptArg,
      scope,
      parentAppId,
      beginLoginAttempt,
      returnUrl,
      onSuccess,
      onRequiresAppMfa,
      onRequiresMfaRegistration,
      onRequiresSmsMfa,
      onRequiresPasswordUpdate,
    ],
    { onFailure, autorun }
  )

  return [validatePassword, { error, loading }]
}

export default useLogInWithPassword
