import { useCallback, useContext } from 'react'

import { PageWrapperContext } from '../../PageWrapper'
import useHomeContext from '../homeContext/useHomeContext'
import useLogInWithPassword from '../serviceHooks/vtexid/useLogInWithPassword'
import CONSTANTS from '../services/vtexid/constants'

const LogInWithPassword = ({
  saveUserAccount,
  onSuccess,
  onRequiresPasswordUpdate,
  onRequiresSmsMfa,
  onRequiresAppMfa,
  onRequiresMfaRegistration,
  onFailure,
  children,
  email,
}) => {
  const { returnUrlQuery } = useContext(PageWrapperContext)

  const { setUserAccounts, handleSessionTimeout } = useHomeContext()

  const [logInWithPassword, { loading }] = useLogInWithPassword({
    scope: CONSTANTS.SCOPES.ADMIN,
    onRequiresMfaRegistration,
    onRequiresPasswordUpdate,
    onRequiresAppMfa,
    onRequiresSmsMfa,
    onSuccess: useCallback(() => {
      setUserAccounts((prevUserAccounts) => {
        if (saveUserAccount && !prevUserAccounts.includes(email)) {
          return [...prevUserAccounts, email]
        }

        return prevUserAccounts
      })

      onSuccess()
    }, [email, onSuccess, saveUserAccount, setUserAccounts]),
    onFailure,
    loginAttempt: {
      onTimeout: handleSessionTimeout,
      returnUrl: returnUrlQuery,
    },
  })

  return children({
    action: logInWithPassword,
    loading,
  })
}

export default LogInWithPassword
