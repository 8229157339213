import validateToken from '../../services/vtexid/validateToken'
import { NOOP } from '../../constants'
import CONSTANTS from '../../services/vtexid/constants'
import useAsyncCallback from '../../services/vtexid/utils/useAsyncCallback'

const { API_AUTH_STATUS } = CONSTANTS

const useLogInWithAccessKey = ({
  onSuccess = NOOP,
  onFailure = NOOP,
  autorun = false,
  parentAppId = null,
  recaptchaToken: recaptchaTokenArg = null,
  actionArgs: {
    email: emailArg = null,
    accessKey: tokenArg = null,
    recaptcha: recaptchaArg = null,
    disableSetPreference: disableSetPreferenceArg = false,
  } = {},
} = {}) => {
  const [verifyToken, { error, loading }] = useAsyncCallback(
    async ({
      email = emailArg,
      accessKey = tokenArg,
      recaptcha = recaptchaArg,
      disableSetPreference = disableSetPreferenceArg,
      recaptchaToken = recaptchaTokenArg,
    } = {}) => {
      const { authStatus } = await validateToken({
        login: email,
        accesskey: accessKey,
        recaptcha,
        recaptchaToken,
        setPreference: !disableSetPreference,
        parentAppId,
      })

      if (authStatus !== API_AUTH_STATUS.Success) {
        throw { authStatus }
      }
    },
    [
      disableSetPreferenceArg,
      emailArg,
      parentAppId,
      recaptchaArg,
      recaptchaTokenArg,
      tokenArg,
    ],
    { onSuccess, onFailure, autorun }
  )

  return [
    verifyToken,
    {
      error,
      loading,
    },
  ]
}

export default useLogInWithAccessKey
