import React from 'react'
import { FormattedMessage } from 'react-intl'

import useHomeContext from '../commons/homeContext/useHomeContext'
import RedirectSaml from '../commons/tempAuthServices/RedirectSaml'
import LoadingWithMessage from './LoadingWithMessage'

const SamlRedirect = () => {
  const { identityProviders, email } = useHomeContext()

  const { samlProviders } = identityProviders
  const { id, idpName: name } = (samlProviders && samlProviders[0]) || {}

  return (
    <React.Fragment>
      <RedirectSaml autorun useNewSession provider={id} email={email} />
      <div data-testid="saml-redirect">
        <LoadingWithMessage
          bottomMessage={
            <FormattedMessage
              id="admin/providerLogin.redirectMessage"
              values={{ provider: name }}
            />
          }
        />
      </div>
    </React.Fragment>
  )
}

export default SamlRedirect
