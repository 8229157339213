import useValidateMfa from '../serviceHooks/vtexid/useValidateMfa'

const ValidateMfa = ({ onSuccess, onFailure, children }) => {
  const [validateMfa, { loading }] = useValidateMfa({
    onSuccess,
    onFailure,
  })

  return children({
    action: validateMfa,
    loading,
  })
}

export default ValidateMfa
