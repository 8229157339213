import { buildFormData, parseResBody } from '../utils'
import BlockedUserError from './error/BlockedUserError'
import ThrottledError from './error/ThrottledError'
import paths from './paths'
import buildReqHeaders from './utils/buildReqHeaders'

const sendVerificationCode = async ({
  email,
  deliveryMethod,
  locale,
  recaptcha,
  recaptchaToken,
  parentAppId,
}) => {
  const body = buildFormData({
    email,
    locale,
    recaptcha,
    recaptchaToken,
    parentAppId,
  })

  const res = await fetch(paths.sendEmailVerification(deliveryMethod), {
    method: 'POST',
    body,
    headers: buildReqHeaders({ parentAppId }),
  })

  if (res.status === 429) {
    throw new ThrottledError()
  }

  if (res.status === 401) {
    throw new BlockedUserError()
  }

  if (!res.ok) {
    throw {
      response: {
        data: await parseResBody(res),
      },
    }
  }

  return res.json()
}

export default sendVerificationCode
