import { NOOP } from '../../constants'
import getFingerprintIfAdmin from '../../getFingerprintIfAdmin'
import CONSTANTS from '../../services/vtexid/constants'
import redirectOAuth from '../../services/vtexid/redirectOAuth'
import useAsyncCallback from '../../services/vtexid/utils/useAsyncCallback'
import useBeginLoginAttempt from './useBeginLoginAttempt'

const useOAuthRedirect = ({
  onSuccess = NOOP,
  onFailure = NOOP,
  autorun = false,
  scope = CONSTANTS.SCOPES.STORE,
  actionArgs: {
    email: emailArg = null,
    provider: providerArg = null,
    errorFallbackUrl: errorFallbackUrlArg = null,
    useNewLoginAttempt: useNewLoginAttemptArg = false,
  } = {},
  loginAttempt: {
    onTimeout: onLoginAttemptTimeout = NOOP,
    returnUrl = null,
  } = {},
} = {}) => {
  const beginLoginAttempt = useBeginLoginAttempt(
    () => onLoginAttemptTimeout(),
    [onLoginAttemptTimeout],
    {
      scope,
      returnUrl,
    }
  )

  const [startSessionAndRedirect, { error, loading }] = useAsyncCallback(
    async ({
      email = emailArg,
      provider = providerArg,
      errorFallbackUrl = errorFallbackUrlArg,
      useNewLoginAttempt = useNewLoginAttemptArg,
    } = {}) => {
      if (useNewLoginAttempt) {
        const fingerprint = await getFingerprintIfAdmin(scope)

        await beginLoginAttempt({ fingerprint, email, returnUrl })
      }

      return redirectOAuth({ provider, errorFallbackUrl })
    },
    [
      emailArg,
      providerArg,
      errorFallbackUrlArg,
      useNewLoginAttemptArg,
      scope,
      beginLoginAttempt,
      returnUrl,
    ],
    { onSuccess, onFailure, autorun }
  )

  return [startSessionAndRedirect, { error, loading }]
}

export default useOAuthRedirect
