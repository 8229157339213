import paths from './paths'

const redirectOAuth = ({ provider, errorFallbackUrl }) => {
  if (!window || !window.location || !provider) {
    return
  }

  window.location.href = paths.getOAuthRedirectUrl({
    providerName: provider,
    errorFallbackUrl,
  })
}

export default redirectOAuth
