import { NOOP } from '../../constants'
import CONSTANTS from '../../services/vtexid/constants'
import useAsyncCallback from '../../services/vtexid/utils/useAsyncCallback'
import serviceValidateMfa from '../../services/vtexid/validateMfa'

const useValidateMfa = ({
  onSuccess = NOOP,
  onFailure = NOOP,
  autorun = false,
  parentAppId = null,
  actionArgs: { mfaToken: mfaTokenArg = null } = {},
} = {}) => {
  const [validateMfa, { error, loading }] = useAsyncCallback(
    async ({ mfaToken = mfaTokenArg } = {}) => {
      const { authStatus } = await serviceValidateMfa({
        mfaToken,
        parentAppId,
      })

      if (authStatus !== CONSTANTS.API_AUTH_STATUS.Success) {
        throw { authStatus }
      }
    },
    [mfaTokenArg, parentAppId],
    { onSuccess, onFailure, autorun }
  )

  return [
    validateMfa,
    {
      error,
      loading,
    },
  ]
}

export default useValidateMfa
