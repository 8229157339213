import useSetPassword from '../serviceHooks/vtexid/useSetPassword'
import CONSTANTS from '../services/vtexid/constants'

const SetPassword = ({ children, onSuccess, onFailure }) => {
  const [setNewPassword, { loading }] = useSetPassword({
    onSuccess,
    onFailure,
    scope: CONSTANTS.SCOPES.ADMIN,
  })

  return children({
    action: setNewPassword,
    loading,
  })
}

export default SetPassword
