import { NOOP } from '../../constants'
import resendMfa from '../../services/vtexid/resendMfa'
import useAsyncCallback from '../../services/vtexid/utils/useAsyncCallback'

const useResendMfaToken = ({
  onSuccess = NOOP,
  onFailure = NOOP,
  autorun = false,
  parentAppId = null,
} = {}) => {
  const [resendMfaToken, { error, loading }] = useAsyncCallback(
    async () => {
      const { authStatus } = await resendMfa({
        parentAppId,
      })

      if (authStatus !== 'Pending') {
        throw { authStatus }
      }
    },
    [parentAppId],
    { onSuccess, onFailure, autorun }
  )

  return [
    resendMfaToken,
    {
      error,
      loading,
    },
  ]
}

export default useResendMfaToken
