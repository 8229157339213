import { useContext } from 'react'

import type { ContextValues } from './context'
import context from './context'

const useHomeContext = (): ContextValues => {
  const contextValues = useContext(context)

  if (!contextValues) {
    throw new Error('Must be used inside HomeProvider')
  }

  return contextValues
}

export default useHomeContext
