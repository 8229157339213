import { parseResBody } from '../utils'
import buildReqHeaders from './utils/buildReqHeaders'
import CONSTANTS from './constants'
import UnexpectedError from './error/UnexpectedError'
import paths from './paths'

const { SCOPES } = CONSTANTS

export const getLoginPreference = async ({
  accountName,
  scopeName,
  email,
  parentAppId,
}) => {
  const scope = scopeName === SCOPES.ADMIN ? '' : accountName

  const res = await fetch(paths.getLoginPreference(scope, email), {
    headers: buildReqHeaders({ parentAppId }),
  })

  if (!res.ok) {
    throw {
      response: {
        data: await parseResBody(res),
      },
    }
  }

  if (res.status !== 200) {
    throw new UnexpectedError()
  }

  return res.json()
}

export default getLoginPreference
