import { NOOP } from '../constants'
import useRegisterMfaAuthenticator from '../serviceHooks/vtexid/useRegisterMfaAuthenticator'
import CONSTANTS from '../services/vtexid/constants'

const { SCOPES } = CONSTANTS

const RegisterMfaAuthenticator = ({
  onSuccess = NOOP,
  onFailure = NOOP,
  skip = false,
  children,
}) => {
  const [
    registerMfaAuthenticator,
    { loading, value, error },
  ] = useRegisterMfaAuthenticator({
    onSuccess,
    onFailure,
    autorun: !skip,
    scope: SCOPES.ADMIN,
    mfaSetupAttempt: {},
  })

  return children({
    action: registerMfaAuthenticator,
    loading,
    error,
    value,
  })
}

export default RegisterMfaAuthenticator
