import PropTypes from 'prop-types'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import Recaptcha from 'react-google-recaptcha'
import { FormattedMessage } from 'react-intl'
import { BooleanValue, Value } from 'react-values'
import { Button, Input, ToastConsumer } from 'vtex.styleguide'

import {
  ERROR,
  RECAPTCHA_SITEKEY,
  RESEND_TIMER_SECONDS,
} from '../commons/constants'
import useHomeContext from '../commons/homeContext/useHomeContext'
import LogInWithAccessKey from '../commons/tempAuthServices/LogInWithAccessKey'
import SendAccessKey from '../commons/tempAuthServices/SendAccessKey'
import { PageWrapperContext } from '../PageWrapper'
import { BorderlessButton, TimerMessage } from './shared'
import getIntlError from './utils/getIntlError'
import useTimer from './utils/useTimer'

const recaptchaRef = React.createRef()

const TokenConfirmation = ({
  onTokenConfirmed,
  onChangeEmail,
  canSetPassword,
  onSwitchToPassword,
  setAlertError,
}) => {
  const { email, token, setToken, identityProviders, recaptcha, setRecaptcha } =
    useHomeContext()

  const { handleIsUnauthorized } = useContext(PageWrapperContext)
  const [showTimer, setShowTimer] = useState(false)
  const hideTimer = useCallback(() => setShowTimer(false), [])
  const [startTimer, getSecsLeft] = useTimer({ onFinish: hideTimer })

  const {
    password: isProviderPasswordActive,
    accessKey: isProviderAccessKeyActive,
  } = identityProviders

  const hasProviderAllActive =
    isProviderPasswordActive && isProviderAccessKeyActive

  const handleResend = useCallback(
    (resend) => {
      if (getSecsLeft()) {
        setShowTimer(true)
      } else {
        resend()
      }
    },
    [getSecsLeft]
  )

  useEffect(() => startTimer(RESEND_TIMER_SECONDS), [startTimer])

  return (
    <ToastConsumer>
      {({ showToast }) => (
        <BooleanValue>
          {({ value: hasRecaptchaError, set: setRecaptchaError }) => (
            <BooleanValue>
              {({ value: isRecaptchaVisible, set: setRecaptchaVisibility }) => (
                <Value defaultValue="">
                  {({
                    value: errorMessage,
                    set: setErrorMessage,
                    reset: removeErrorMessage,
                  }) => (
                    <React.Fragment>
                      <div>
                        <div className="t-heading-4 c-on-base mb5">
                          <FormattedMessage id="admin/tokenConfirmation.codeSentByEmail" />
                        </div>
                        <div className="t-body-ns t-small c-muted-1 lh-copy">
                          <FormattedMessage id="admin/tokenConfirmation.insertNumbers" />{' '}
                          <div className="serious-black dib">{email}</div>
                        </div>
                      </div>
                      <div className="flex pb3 mt5 t-small">
                        <SendAccessKey
                          onSuccess={() => {
                            if (recaptchaRef && recaptchaRef.current) {
                              recaptchaRef.current.reset()
                            }

                            showToast({
                              message: (
                                <FormattedMessage id="admin/tokenConfirmation.tokenResent" />
                              ),
                            })
                            setAlertError('')
                            startTimer(RESEND_TIMER_SECONDS)
                          }}
                          onFailure={({ code }) => {
                            if (recaptchaRef && recaptchaRef.current) {
                              recaptchaRef.current.reset()
                            }

                            if (code === ERROR.THROTTLED) {
                              setAlertError(
                                <FormattedMessage id="admin/error.ThrottledError" />
                              )

                              return
                            }

                            setAlertError(
                              <FormattedMessage id="admin/tokenConfirmation.resendTokenError" />
                            )
                          }}>
                          {({ loading, action: sendToken }) => (
                            <span className="dark-gray">
                              <BorderlessButton
                                onClick={() =>
                                  handleResend(() =>
                                    sendToken({
                                      email,
                                      recaptcha,
                                    })
                                  )
                                }
                                loading={loading}
                                disabled={showTimer}>
                                <FormattedMessage id="admin/tokenConfirmation.resendCode" />
                              </BorderlessButton>
                            </span>
                          )}
                        </SendAccessKey>
                        <div className="ml6">
                          <BorderlessButton onClick={onChangeEmail}>
                            <span
                              data-testid="change-email"
                              className="t-small">
                              <FormattedMessage id="admin/tokenConfirmation.changeEmail" />
                            </span>
                          </BorderlessButton>
                        </div>
                      </div>
                      {showTimer && <TimerMessage getSecsLeft={getSecsLeft} />}
                      <div className="mv6 mb0-s">
                        <div className="v-btm" data-testid="token-input">
                          <Input
                            token
                            type="text"
                            pattern="\d*"
                            size="large"
                            label={
                              <FormattedMessage id="admin/tokenConfirmation.inputLabel.code" />
                            }
                            value={token || ''}
                            errorMessage={errorMessage}
                            onChange={(e) => {
                              setToken(e.target.value)
                              removeErrorMessage()
                            }}
                          />
                        </div>

                        <div className="mt5">
                          <LogInWithAccessKey
                            onSuccess={onTokenConfirmed}
                            onFailure={(error) => {
                              if (recaptchaRef && recaptchaRef.current) {
                                recaptchaRef.current.reset()
                              }

                              if (error.code == 'UnauthorizedUser') {
                                handleIsUnauthorized(true)
                                onChangeEmail()

                                return
                              }

                              if (error.code == 'WrongCredentials') {
                                setErrorMessage(
                                  <FormattedMessage id="admin/tokenConfirmation.incorrectTokenError" />
                                )

                                return
                              }

                              if (
                                ['BlockedUser', 'InvalidRecaptcha'].includes(
                                  error.code
                                )
                              ) {
                                if (isRecaptchaVisible) {
                                  setRecaptchaError(true)
                                } else {
                                  setRecaptchaVisibility(true)
                                }

                                return
                              }

                              setAlertError(getIntlError(error.code))
                            }}
                            saveUserAccount
                            email={email}>
                            {({ loading, action: verifyToken }) => (
                              <div>
                                {isRecaptchaVisible && (
                                  <div className="mb5">
                                    <div className="mb5 mt6 t-body-ns t-small c-on-base">
                                      <FormattedMessage id="admin/recaptcha.explanation" />
                                    </div>
                                    <Recaptcha
                                      sitekey={RECAPTCHA_SITEKEY}
                                      ref={recaptchaRef}
                                      onChange={(newRecaptcha) => {
                                        setRecaptchaError(false)
                                        setRecaptcha(newRecaptcha)
                                      }}
                                    />
                                    {hasRecaptchaError && (
                                      <div className="c-danger t-small mt3 lh-title">
                                        <FormattedMessage id="admin/passwordLogin.recaptchaMissing" />
                                      </div>
                                    )}
                                  </div>
                                )}
                                <Button
                                  block
                                  variation="primary"
                                  isLoading={loading}
                                  onClick={() => {
                                    if (token === '' || !token) {
                                      setErrorMessage(
                                        <FormattedMessage id="admin/tokenConfirmation.codeIsRequired" />
                                      )
                                    } else if (token.length !== 6) {
                                      setErrorMessage(
                                        <FormattedMessage id="admin/tokenConfirmation.codeMustHave6Numbers" />
                                      )
                                    } else {
                                      setRecaptchaError(false)
                                      removeErrorMessage()
                                      verifyToken({
                                        email,
                                        accessKey: token,
                                        recaptcha,
                                        disableSetPreference:
                                          !hasProviderAllActive,
                                      })
                                    }
                                  }}>
                                  <div data-testid="token-screen-continue">
                                    <FormattedMessage id="admin/button.continue" />
                                  </div>
                                </Button>
                              </div>
                            )}
                          </LogInWithAccessKey>
                          {canSetPassword && (
                            <div
                              className="dib w-100 mt6 mb7 flex justify-center"
                              data-testid="switch-to-password">
                              <LogInWithAccessKey
                                onSuccess={onSwitchToPassword}
                                onFailure={(error) => {
                                  if (error.code === 'WrongCredentials') {
                                    setErrorMessage(
                                      <FormattedMessage id="admin/tokenConfirmation.incorrectTokenError" />
                                    )
                                  } else {
                                    setAlertError(getIntlError(error.code))
                                  }
                                }}>
                                {({ action: verifyToken }) => (
                                  <a
                                    onClick={() => {
                                      if (token === '' || !token) {
                                        setErrorMessage(
                                          <FormattedMessage id="admin/tokenConfirmation.codeIsRequired" />
                                        )
                                      } else if (token.length !== 6) {
                                        setErrorMessage(
                                          <FormattedMessage id="admin/tokenConfirmation.codeMustHave6Numbers" />
                                        )
                                      } else {
                                        verifyToken({
                                          email,
                                          accessKey: token,
                                          recaptcha,
                                          disableSetPreference:
                                            !hasProviderAllActive,
                                        })
                                      }
                                    }}
                                    className="fr link c-link f6 pointer"
                                    data-testid="validate-code-create-password">
                                    <FormattedMessage id="admin/tokenConfirmation.validateAndCreatePassword" />
                                  </a>
                                )}
                              </LogInWithAccessKey>
                            </div>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </Value>
              )}
            </BooleanValue>
          )}
        </BooleanValue>
      )}
    </ToastConsumer>
  )
}

TokenConfirmation.propTypes = {
  onTokenConfirmed: PropTypes.func.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  onSwitchToPassword: PropTypes.func,
  canSetPassword: PropTypes.bool,
  setAlertError: PropTypes.func.isRequired,
}

export default TokenConfirmation
