import { useRef, useCallback } from 'react'
import { useRuntime } from 'vtex.render-runtime'

import CONSTANTS from '../../services/vtexid/constants'
import startSession from '../../services/vtexid/startSession'

const { SESSION_DURATION: ATTEMPT_DURATION } = CONSTANTS

const useBeginLoginAttempt = (
  attemptTimeoutCallback,
  depsLst,
  {
    scope = CONSTANTS.SCOPES.STORE,
    parentAppId = null,
    useOAuthPopup = false,
  } = {}
) => {
  const { account } = useRuntime()

  const attemptTimeoutIdRef = useRef()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedAttemptTimeoutCallback = useCallback(
    attemptTimeoutCallback,
    depsLst
  )

  return useCallback(
    ({ fingerprint = null, email = null, returnUrl = null }) => {
      clearTimeout(attemptTimeoutIdRef.current)
      attemptTimeoutIdRef.current = setTimeout(
        () => memoizedAttemptTimeoutCallback(),
        ATTEMPT_DURATION
      )

      return startSession({
        accountName: account,
        returnUrl,
        scope,
        user: email,
        fingerprint,
        parentAppId,
        useOAuthPopup,
      })
    },
    [account, scope, parentAppId, useOAuthPopup, memoizedAttemptTimeoutCallback]
  )
}

export default useBeginLoginAttempt
