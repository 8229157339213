import { useCallback } from 'react'

import useHomeContext from '../homeContext/useHomeContext'
import useLogInWithAccessKey from '../serviceHooks/vtexid/useLogInWithAccessKey'

const LogInWithAccessKey = ({
  email,
  onSuccess,
  onFailure,
  saveUserAccount,
  children,
}) => {
  const { setUserAccounts } = useHomeContext()
  const [logInWithAccessKey, { loading }] = useLogInWithAccessKey({
    onSuccess: useCallback(() => {
      setUserAccounts((prevUserAccounts) => {
        if (saveUserAccount && !prevUserAccounts.includes(email)) {
          return [...prevUserAccounts, email]
        }

        return prevUserAccounts
      })

      onSuccess()
    }, [email, onSuccess, saveUserAccount, setUserAccounts]),
    onFailure,
  })

  return children({
    action: logInWithAccessKey,
    loading,
  })
}

export default LogInWithAccessKey
