import getAbsReturnUrl from './getAbsReturnUrl'
import paths from './paths'

export const redirect = ({ returnUrl }) => {
  if (!window || !window.location) {
    return
  }

  const absoluteReturnUrl = getAbsReturnUrl(returnUrl)

  window.location.href = paths.redirect(absoluteReturnUrl)
}

export default redirect
