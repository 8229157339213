import useGetLoginPreference from '../serviceHooks/vtexid/useGetLoginPreference'
import CONSTANTS from '../services/vtexid/constants'

const { SCOPES } = CONSTANTS

const GetLoginPreference = ({ onSuccess, onFailure, children }) => {
  const [getLoginPreference, { loading, value, error }] = useGetLoginPreference(
    {
      onSuccess,
      onFailure,
      scope: SCOPES.ADMIN,
    }
  )

  return children({
    action: getLoginPreference,
    loading,
    error,
    value,
  })
}

export default GetLoginPreference
