import { buildFormData, parseResBody } from '../utils'
import paths from './paths'
import buildReqHeaders from './utils/buildReqHeaders'

const validateMfa = async ({ mfaToken, recaptcha, parentAppId }) => {
  const body = buildFormData({
    mfaToken,
    recaptcha,
  })

  const res = await fetch(paths.validateMfa(), {
    method: 'POST',
    body,
    headers: buildReqHeaders({ parentAppId }),
  })

  if (!res.ok) {
    throw {
      response: {
        data: await parseResBody(res),
      },
    }
  }

  return res.json()
}

export default validateMfa
