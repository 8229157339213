import type { ReactNode } from 'react'
import React, { useCallback, useState } from 'react'

import { SELF_APP_NAME_AND_VERSION } from '../constants'
import useInitialAuthData from '../serviceHooks/vtexid/useInitialAuthData'
import CONSTANTS from '../services/vtexid/constants'
import UnexpectedError from '../services/vtexid/error/UnexpectedError'
import useUserAccounts from '../useUserAccounts'
import hasOnlyNumbers from '../validations/hasOnlyNumbers'
import validateEmail from '../validations/validateEmail'
import type { IdentityProviders } from './context'
import context from './context'

interface Props {
  children: ReactNode
  email: string
}

const shouldShowGoogleLogin = (providers: IdentityProviders) =>
  providers &&
  providers.oAuthProviders &&
  providers.oAuthProviders.length > 0 &&
  providers.oAuthProviders.some((idp) => idp.providerName === 'Google')

const Provider = ({ children, email: initialEmail }: Props) => {
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const [userAccounts, setUserAccounts] = useUserAccounts()
  const [email, setEmail] = useState(
    validateEmail(initialEmail) ? initialEmail : null
  )

  const [password, setPassword] = useState('')
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null)
  const [token, setToken] = useState<string | null>(null)

  const setValidToken = useCallback(
    (_token: string) => {
      if (_token === '' || hasOnlyNumbers(_token)) {
        setToken(_token.substring(0, 6))
      }
    },
    [setToken]
  )

  const [currentPassword, setCurrentPassword] = useState<string | null>(null)
  const [recaptcha, setRecaptcha] = useState<string | null>(null)
  const {
    loading,
    error,
    value: {
      identityProviders,
      reauthenticationResult: { isUserAuthenticated, userId },
    },
  } = useInitialAuthData({
    autorun: true,
    scope: CONSTANTS.SCOPES.ADMIN,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    parentAppId: SELF_APP_NAME_AND_VERSION,
  })

  const handleSessionTimeout = useCallback(() => setIsSessionExpired(true), [])

  return (
    <context.Provider
      value={{
        userAccounts,
        setUserAccounts,
        email,
        setEmail,
        password,
        setPassword,
        currentPassword,
        setCurrentPassword,
        phoneNumber,
        setPhoneNumber,
        token,
        setToken: setValidToken,
        recaptcha,
        setRecaptcha,
        identityProviders: {
          ...identityProviders,
          googleOAuth: shouldShowGoogleLogin(identityProviders),
        },
        isSessionExpired,
        handleSessionTimeout,
      }}>
      {typeof children === 'function'
        ? children({
            loading,
            error:
              !identityProviders && !loading ? new UnexpectedError() : error,
            identityProviders,
            isUserAuthenticated,
            userId,
            isSessionExpired,
          })
        : children}
    </context.Provider>
  )
}

export default Provider
