import React, { useCallback, useContext, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useRuntime } from 'vtex.render-runtime'

import { PAGE } from '../commons/constants'
import useHomeContext from '../commons/homeContext/useHomeContext'
import useRedirectAfterLogin from '../commons/serviceHooks/vtexid/useRedirectAfterLogin'
import { PageWrapperContext } from '../PageWrapper'
import LoadingWithMessage from './LoadingWithMessage'
import getUserAdminPermission from './utils/getUserAdminPermission'

const Redirect = ({ email, userId, redirectToReturnUrl }) => {
  const { account, navigate, rootPath } = useRuntime()

  const checkPermissionAndRedirect = useCallback(async () => {
    const hasPermission = await getUserAdminPermission({
      userId: userId || email,
      account,
      rootPath,
    })

    if (hasPermission) {
      redirectToReturnUrl()

      return
    }

    navigate({ page: PAGE.UNAUTHORIZED })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, email, navigate, redirectToReturnUrl, userId])

  useEffect(() => {
    checkPermissionAndRedirect()
  }, [checkPermissionAndRedirect])

  return (
    <LoadingWithMessage
      bottomMessage={<FormattedMessage id="admin/authenticatedUser.welcome" />}
    />
  )
}

const RedirectWrapped = ({ userId }) => {
  const { email } = useHomeContext()
  const { returnUrlQuery } = useContext(PageWrapperContext)
  const [redirectToReturnUrl] = useRedirectAfterLogin({
    actionArgs: { returnUrl: returnUrlQuery },
  })

  return (
    <Redirect
      userId={userId}
      email={email}
      redirectToReturnUrl={redirectToReturnUrl}
    />
  )
}

export default RedirectWrapped
