import { useRuntime } from 'vtex.render-runtime'

import { NOOP } from '../../constants'
import CONSTANTS from '../../services/vtexid/constants'
import setPasswordAfterLogin from '../../services/vtexid/setPasswordAfterLogin'
import useAsyncCallback from '../../services/vtexid/utils/useAsyncCallback'

const useSetPasswordAfterLogin = ({
  onSuccess = NOOP,
  onFailure = NOOP,
  autorun = false,
  scope = CONSTANTS.SCOPES.STORE,
  parentAppId = null,
  actionArg: {
    password: passwordArg = null,
    disableSetPreference: disableSetPreferenceArg = false,
  } = {},
} = {}) => {
  const { account } = useRuntime()

  const [setPassword, { error, loading }] = useAsyncCallback(
    async ({
      password = passwordArg,
      disableSetPreference = disableSetPreferenceArg,
    } = {}) => {
      const { authStatus } = (await setPasswordAfterLogin({
        accountName: account,
        scope,
        password,
        parentAppId,
        setPreference: !disableSetPreference,
      })) || { authStatus: 'UnexpectedError' }

      if (authStatus !== CONSTANTS.API_AUTH_STATUS.Success) {
        throw { authStatus }
      }
    },
    [passwordArg, disableSetPreferenceArg, account, scope, parentAppId],
    { onSuccess, onFailure, autorun }
  )

  return [
    setPassword,
    {
      error,
      loading,
    },
  ]
}

export default useSetPasswordAfterLogin
