import { useRuntime } from 'vtex.render-runtime'

import { NOOP } from '../../constants'
import getInitialAuthData from '../../services/vtexid/getInitialAuthData'
import useAsyncCallback from '../../services/vtexid/utils/useAsyncCallback'

const useInitialAuthData = ({
  scope = 'STORE',
  onSuccess = NOOP,
  onFailure = NOOP,
  autorun = false,
  parentAppId = null,
} = {}) => {
  const { account } = useRuntime()

  const [, { value, error, loading }] = useAsyncCallback(
    async () => {
      const result = await getInitialAuthData({
        accountName: account,
        scopeName: scope,
        parentAppId,
      })

      const {
        identityProviders: {
          oAuthProviders,
          samlProviders,
          passwordAuthentication: password,
          accessKeyAuthentication: accessKey,
          passkeyAuthentication: passKey,
          corporateProviders,
        },
        reauthenticationResult,
        fingerprint,
      } = result

      return {
        identityProviders: {
          oAuthProviders,
          samlProviders,
          password,
          accessKey,
          passKey,
          corporateProviders,
        },
        reauthenticationResult,
        fingerprint,
      }
    },
    [account, parentAppId, scope],
    {
      onSuccess,
      onFailure,
      autorun,
    }
  )

  return {
    value: value || {
      identityProviders: null,
      reauthenticationResult: {
        isUserAuthenticated: false,
        userId: null,
      },
    },
    error,
    loading,
  }
}

export default useInitialAuthData
