import { useRuntime } from 'vtex.render-runtime'

import { NOOP } from '../../constants'
import getFingerprintIfAdmin from '../../getFingerprintIfAdmin'
import CONSTANTS from '../../services/vtexid/constants'
import sendVerificationCode from '../../services/vtexid/sendVerificationCode'
import useAsyncCallback from '../../services/vtexid/utils/useAsyncCallback'
import useBeginLoginAttempt from './useBeginLoginAttempt'

const useSendAccessKey = ({
  onSuccess = NOOP,
  onFailure = NOOP,
  autorun = false,
  scope = CONSTANTS.SCOPES.STORE,
  parentAppId = null,
  actionArgs: {
    email: emailArg = null,
    deliveryMethod: deliveryMethodArg = 'email',
    preference: preferenceArg = null,
    recaptcha: recaptchaArg = null,
    recaptchaToken: recaptchaTokenArg = null,
    useNewLoginAttempt: useNewLoginAttemptArg = false,
  } = {},
  loginAttempt: {
    onTimeout: onLoginAttemptTimeout = NOOP,
    returnUrl = null,
  } = {},
} = {}) => {
  const {
    culture: { locale },
  } = useRuntime()

  const beginLoginAttempt = useBeginLoginAttempt(
    () => onLoginAttemptTimeout(),
    [onLoginAttemptTimeout],
    {
      scope,
      parentAppId,
    }
  )

  const [sendToken, { error, loading }] = useAsyncCallback(
    async ({
      email = emailArg,
      deliveryMethod = deliveryMethodArg,
      preference = preferenceArg,
      recaptcha = recaptchaArg,
      recaptchaToken = recaptchaTokenArg,
      useNewLoginAttempt = useNewLoginAttemptArg,
    } = {}) => {
      if (useNewLoginAttempt) {
        const fingerprint = await getFingerprintIfAdmin(scope)

        await beginLoginAttempt({ fingerprint, email, returnUrl })
      }

      const result = await sendVerificationCode({
        email,
        deliveryMethod,
        locale,
        recaptcha,
        recaptchaToken,
        parentAppId,
      })

      return {
        email,
        ...(preference && { preference }),
        ...result,
      }
    },
    [
      emailArg,
      deliveryMethodArg,
      preferenceArg,
      recaptchaArg,
      recaptchaTokenArg,
      useNewLoginAttemptArg,
      locale,
      parentAppId,
      scope,
      beginLoginAttempt,
      returnUrl,
    ],
    { onSuccess, onFailure, autorun }
  )

  return [
    sendToken,
    {
      error,
      loading,
    },
  ]
}

export default useSendAccessKey
