import { buildFormData } from '../utils'
import CONSTANTS from './constants'
import paths from './paths'
import buildReqHeaders from './utils/buildReqHeaders'
import getAbsReturnUrl from './utils/getAbsReturnUrl'
import getOAuthCallbackUrl from './utils/getOAuthCallbackUrl'

const startSession = async ({
  accountName,
  returnUrl,
  scope,
  user,
  fingerprint,
  useOAuthPopup,
  parentAppId,
}) => {
  const { href } = (window && window.location) || {}
  const absoluteReturnUrl = getAbsReturnUrl(returnUrl)

  const absoluteCallbackUrl = new URL(
    getOAuthCallbackUrl({ isPopup: useOAuthPopup }),
    href
  )

  const body = buildFormData({
    accountName,
    scope: scope === CONSTANTS.SCOPES.ADMIN ? '' : accountName,
    returnUrl: absoluteReturnUrl,
    callbackUrl: absoluteCallbackUrl.href,
    user,
    fingerprint,
  })

  const res = await fetch(paths.startLogin(), {
    method: 'POST',
    body,
    headers: buildReqHeaders({ parentAppId }),
  })

  if (!res.ok) {
    throw {
      response: {},
    }
  }
}

export default startSession
