import getFingerprintIfAdmin from '../../getFingerprintIfAdmin'
import CONSTANTS from './constants'
import getIdentityProviders from './getIdentityProviders'
import reauthenticateUser from './reauthenticateUser'

const initialReauthentication = async ({
  fingerprintPromise,
  scopeName,
  parentAppId,
  isAdmin,
}) => {
  if (isAdmin) {
    const fingerprint = await fingerprintPromise

    const data = await reauthenticateUser({
      scopeName,
      parentAppId,
      fingerprint,
    })

    if (!data || data.status !== 'Success') {
      return {
        isUserAuthenticated: false,
        userId: null,
      }
    }

    return {
      isUserAuthenticated: true,
      userId: data.userId,
    }
  }

  return {
    isUserAuthenticated: false,
    userId: null,
  }
}

const getInitialAuthData = async ({ accountName, scopeName, parentAppId }) => {
  const isAdmin = scopeName === CONSTANTS.SCOPES.ADMIN

  const fingerprintPromise = getFingerprintIfAdmin(scopeName)

  const [identityProviders, reauthenticationResult, fingerprint] =
    await Promise.all([
      getIdentityProviders({ accountName, scopeName, parentAppId }),
      initialReauthentication({
        fingerprintPromise,
        scopeName,
        parentAppId,
        isAdmin,
      }),
      fingerprintPromise,
    ])

  return { identityProviders, reauthenticationResult, fingerprint }
}

export default getInitialAuthData
