import { useCallback, useEffect } from 'react'

import redirectService from '../../services/vtexid/redirect'

const useRedirectAfterLogin = ({
  autorun = false,
  actionArgs: { returnUrl: returnUrlArg = null } = {},
} = {}) => {
  const redirect = useCallback(
    ({ returnUrl = returnUrlArg } = {}) => redirectService({ returnUrl }),
    [returnUrlArg]
  )

  useEffect(() => {
    if (autorun) {
      redirect()
    }
  }, [autorun, redirect])

  return [redirect]
}

export default useRedirectAfterLogin
