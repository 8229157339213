import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { Alert } from 'vtex.styleguide'

import useHomeContext from '../../commons/homeContext/useHomeContext'
import { PageWrapperContext } from '../../PageWrapper'
import ExternalProviders from '../ExternalProviders'
import EmailForm from './EmailForm'

const messages = defineMessages({
  httpLink: {
    id: 'admin/dontHaveAnAccount.httpLink',
    defaultMessage:
      'https://help.vtex.com/tutorial/how-to-manage-users--tutorials_512',
  },
})

const RequestEmail = ({
  onLogInWithToken,
  onLogInWithPassword,
  onFirstLogin,
  setAlertError,
  onSamlLogin,
  onOauthLogin,
}) => {
  const { isUnauthorized, handleIsUnauthorized } =
    useContext(PageWrapperContext)

  const handleSamlLogin = onSamlLogin
  const handleOauthLogin = onOauthLogin
  const intl = useIntl()
  const { identityProviders, email } = useHomeContext()

  const { googleOAuth, samlProviders, password, accessKey } = identityProviders

  const hasNativeAndExternalLogins =
    (googleOAuth || samlProviders.length > 0) && (password || accessKey)

  return (
    <React.Fragment>
      {isUnauthorized && email && (
        <div className="nt4 mb6">
          <Alert type="error" onClose={() => handleIsUnauthorized(false)}>
            <span
              className="t-small t-body-ns"
              style={{ wordBreak: 'break-word' }}>
              <FormattedMessage
                id="admin/unauthorized.alertMessage"
                values={{
                  email,
                }}
              />
            </span>
          </Alert>
        </div>
      )}
      <div className="t-heading-4 mb7 c-on-base">
        <FormattedMessage id="admin/helloLogInToYourAdmin" />
      </div>
      <div className="mt5">
        {(password || accessKey) && (
          <EmailForm
            onLogInWithToken={onLogInWithToken}
            onLogInWithPassword={onLogInWithPassword}
            onFirstLogin={onFirstLogin}
            setAlertError={setAlertError}
            isProviderPasswordActive={password}
            isProviderAccessKeyActive={accessKey}
          />
        )}
        <div className="dib w-100 flex flex-column">
          {hasNativeAndExternalLogins && (
            <div className="flex flex-row w-100 nowrap items-center center mb4">
              <hr className="ma0 b--black-10 bb bb-0 w-50" />
              <span className="dark-gray mh3 tt c-muted-1 t-small">
                <FormattedMessage id="admin/divider.or" />
              </span>
              <hr className="ma0 b--black-10 bb bb-0 w-50" />
            </div>
          )}
          <ExternalProviders
            onSamlLogin={handleSamlLogin}
            onOauthLogin={handleOauthLogin}
            googleOAuth={googleOAuth}
            samlProviders={samlProviders}
            setAlertError={setAlertError}
          />
        </div>
        <div className="c-muted-2 t-small tc">
          <FormattedMessage
            id="admin/dontHaveAnAccount.text"
            values={{
              link: (
                <a
                  className="c-action-primary link pointer"
                  rel="noreferrer noopener"
                  target="_blank"
                  href={intl.formatMessage(messages.httpLink)}>
                  <FormattedMessage id="admin/dontHaveAnAccount.link" />
                </a>
              ),
            }}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

RequestEmail.propTypes = {
  onLogInWithToken: PropTypes.func.isRequired,
  onLogInWithPassword: PropTypes.func.isRequired,
  onFirstLogin: PropTypes.func.isRequired,
  onOauthLogin: PropTypes.func.isRequired,
  onSamlLogin: PropTypes.func.isRequired,
  setAlertError: PropTypes.func.isRequired,
}

export default RequestEmail
