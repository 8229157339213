import { useCallback, useRef } from 'react'

import CONSTANTS from '../../services/vtexid/constants'
import startMfaSetup from '../../services/vtexid/startMfaSetup'

const { SESSION_DURATION: ATTEMPT_DURATION } = CONSTANTS

const useBeginMfaSetupAttempt = (
  attemptTimeoutCallback,
  depsLst,
  { parentAppId = null }
) => {
  const attemptTimeoutIdRef = useRef(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedAttemptTimeoutCallback = useCallback(
    attemptTimeoutCallback,
    depsLst
  )

  return useCallback(() => {
    clearTimeout(attemptTimeoutIdRef.current)
    attemptTimeoutIdRef.current = setTimeout(
      () => memoizedAttemptTimeoutCallback(),
      ATTEMPT_DURATION
    )

    return startMfaSetup({ parentAppId })
  }, [memoizedAttemptTimeoutCallback, parentAppId])
}

export default useBeginMfaSetupAttempt
