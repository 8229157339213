import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { Value } from 'react-values'
import { Button, InputPassword } from 'vtex.styleguide'

import useHomeContext from '../../commons/homeContext/useHomeContext'
import SetPassword from '../../commons/tempAuthServices/SetPassword'
import validatePassword from '../../commons/validations/validatePassword'
import { PasswordRequirement } from '../shared'
import getIntlError from '../utils/getIntlError'
import joinErrorMessages from '../utils/joinErrorMessages'
import useGetPasswordErrors from '../utils/useGetPasswordErrors'

const messages = defineMessages({
  separator: {
    id: 'admin/passwordLogin.separator',
    defaultMessage: '',
  },
  lastSeparator: {
    id: 'admin/passwordLogin.lastSeparator',
    defaultMessage: '',
  },
})

const UpdateExpiredPassword = ({ onPasswordUpdated, setAlertError }) => {
  const { email, password, setPassword, currentPassword, token, recaptcha } =
    useHomeContext()

  const getPasswordErrors = useGetPasswordErrors()
  const { formatMessage } = useIntl()

  return (
    <Fragment>
      <div>
        <div className="t-heading-4 c-on-base mb5">
          <FormattedMessage id="admin/updatePassword.createNewPassword" />
        </div>
        <div className="t-body-ns t-small c-muted-1 lh-copy">
          <FormattedMessage id="admin/updatePassword.passwordExpired" />
        </div>
      </div>
      <Value defaultValue="">
        {({
          value: errorMessage,
          set: setErrorMessage,
          reset: removeErrorMessage,
        }) => {
          const {
            passwordIsValid,
            hasMinLength,
            hasNumber,
            hasUpperCaseLetter,
            hasLowerCaseLetter,
            hasLetter,
          } = validatePassword(password || '')

          return (
            <div className="dib mt6 w-100">
              <InputPassword
                name="password"
                id="forgotpass--pass"
                size="large"
                label={
                  <FormattedMessage id="admin/udpatePassword.inputLabel.password" />
                }
                value={password || ''}
                onChange={(e) => {
                  removeErrorMessage()
                  setPassword(e.target.value)
                }}
                errorMessage={errorMessage}
              />
              <div className="mv4">
                <SetPassword
                  onSuccess={onPasswordUpdated}
                  onFailure={(error) => {
                    setAlertError(getIntlError(error.code))
                  }}>
                  {({ loading, action: updatePassword }) => (
                    <Button
                      variation="primary"
                      block
                      isLoading={loading}
                      onClick={() => {
                        if (passwordIsValid) {
                          updatePassword({
                            newPassword: password,
                            currentPassword,
                            token,
                            recaptcha,
                            email,
                          })
                        } else {
                          setErrorMessage(
                            <FormattedMessage
                              id="admin/passwordLogin.inlinePasswordError"
                              values={{
                                errors: joinErrorMessages(
                                  getPasswordErrors(password),
                                  formatMessage(messages.separator),
                                  formatMessage(messages.lastSeparator)
                                ),
                              }}
                            />
                          )
                        }
                      }}>
                      <FormattedMessage id="admin/updatePassword.buttton.createPassword" />
                    </Button>
                  )}
                </SetPassword>
              </div>
              <PasswordRequirement
                hasEightCharacters={hasMinLength}
                hasNumber={hasNumber}
                hasUppercaseLetter={hasUpperCaseLetter}
                hasLowercaseLetter={hasLowerCaseLetter}
                hasLetter={hasLetter}
              />
            </div>
          )
        }}
      </Value>
    </Fragment>
  )
}

UpdateExpiredPassword.propTypes = {
  onPasswordUpdated: PropTypes.func.isRequired,
  setAlertError: PropTypes.func.isRequired,
}

export default UpdateExpiredPassword
