import { parseResBody } from '../utils'
import paths from './paths'
import buildReqHeaders from './utils/buildReqHeaders'

const registerMfaAuthenticator = async ({ parentAppId }) => {
  const res = await fetch(paths.registerMfaAuthenticator(), {
    method: 'POST',
    headers: buildReqHeaders({ parentAppId }),
  })

  if (!res.ok) {
    throw {
      response: {
        data: await parseResBody(res),
      },
    }
  }

  const resBody = await res.json()

  if (!resBody || !resBody.qRCodeUrl) {
    return resBody
  }

  return {
    ...resBody,
    qRCodeUrl: resBody.qRCodeUrl.replace('chs=150x150', 'chs=200x200'),
  }
}

export default registerMfaAuthenticator
