import { NOOP } from '../../constants'
import getFingerprintIfAdmin from '../../getFingerprintIfAdmin'
import CONSTANTS from '../../services/vtexid/constants'
import setPassword from '../../services/vtexid/setPassword'
import useAsyncCallback from '../../services/vtexid/utils/useAsyncCallback'
import useBeginLoginAttempt from './useBeginLoginAttempt'

const useSetPassword = ({
  onSuccess = NOOP,
  onFailure = NOOP,
  autorun = false,
  scope = CONSTANTS.SCOPES.STORE,
  parentAppId = null,
  actionArgs: {
    email: emailArg = null,
    newPassword: newPasswordArg = null,
    currentPassword: currentPasswordArg = null,
    token: tokenArg = null,
    recaptcha: recaptchaArg = null,
    useNewLoginAttempt: useNewLoginAttemptArg = false,
  } = {},
  loginAttempt: {
    onTimeout: onLoginAttemptTimeout = NOOP,
    returnUrl = null,
  } = {},
} = {}) => {
  const beginLoginAttempt = useBeginLoginAttempt(
    () => onLoginAttemptTimeout(),
    [onLoginAttemptTimeout],
    {
      parentAppId,
      scope,
    }
  )

  const [actionSetPassword, { error, loading }] = useAsyncCallback(
    async ({
      email = emailArg,
      newPassword = newPasswordArg,
      currentPassword = currentPasswordArg,
      token = tokenArg,
      recaptcha = recaptchaArg,
      useNewLoginAttempt = useNewLoginAttemptArg,
    } = {}) => {
      if (useNewLoginAttempt) {
        const fingerprint = await getFingerprintIfAdmin(scope)

        await beginLoginAttempt({ fingerprint, email, returnUrl })
      }

      const { authStatus } = (await setPassword({
        login: email,
        newPassword,
        currentPassword,
        accesskey: token,
        recaptcha,
        parentAppId,
      })) || { authStatus: 'UnexpectedError' }

      if (authStatus !== CONSTANTS.API_AUTH_STATUS.Success) {
        throw { authStatus }
      }
    },
    [
      emailArg,
      newPasswordArg,
      currentPasswordArg,
      tokenArg,
      recaptchaArg,
      useNewLoginAttemptArg,
      parentAppId,
      scope,
      beginLoginAttempt,
      returnUrl,
    ],
    { onSuccess, onFailure, autorun }
  )

  return [
    actionSetPassword,
    {
      error,
      loading,
    },
  ]
}

export default useSetPassword
