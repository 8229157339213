import { useRuntime } from 'vtex.render-runtime'

import { NOOP } from '../../constants'
import CONSTANTS from '../../services/vtexid/constants'
import getLoginPreference from '../../services/vtexid/getLoginPreference'
import useAsyncCallback from '../../services/vtexid/utils/useAsyncCallback'

const { SCOPES } = CONSTANTS

const preferenceByKey = {
  NoPreference: 'NO_PREFERENCE',
  Password: 'PASSWORD',
  AccessKey: 'TOKEN',
}

const useGetLoginPreference = ({
  onSuccess = NOOP,
  onFailure = NOOP,
  autorun = false,
  scope = SCOPES.STORE,
  parentAppId = null,
  actionArgs: { email: emailArg = null } = {},
} = {}) => {
  const { account } = useRuntime()

  return useAsyncCallback(
    async ({ email = emailArg } = {}) => {
      const data = await getLoginPreference({
        accountName: account,
        scopeName: scope,
        email,
        parentAppId,
      })

      return preferenceByKey[data]
    },
    [emailArg, account, scope, parentAppId],
    { onSuccess, onFailure, autorun }
  )
}

export default useGetLoginPreference
