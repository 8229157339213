import { NOOP } from '../constants'
import useResendMfaToken from '../serviceHooks/vtexid/useResendMfaToken'

const ResendMfaToken = ({ onSuccess = NOOP, onFailure = NOOP, children }) => {
  const [resendMfaToken, { loading }] = useResendMfaToken({
    onSuccess,
    onFailure,
  })

  return children({
    action: resendMfaToken,
    loading,
  })
}

export default ResendMfaToken
