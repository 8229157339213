import { parseResBody } from '../utils'
import CONSTANTS from './constants'
import paths from './paths'
import buildReqHeaders from './utils/buildReqHeaders'

const setPasswordAfterLogin = async ({
  accountName,
  scope,
  password,
  setPreference,
  parentAppId,
}) => {
  const body = {
    password,
    expireSessions: true,
  }

  const account = scope === CONSTANTS.SCOPES.ADMIN ? '' : accountName

  const res = await fetch(paths.setPasswordAfterLogin(account, setPreference), {
    method: 'POST',
    body: JSON.stringify(body),
    headers: buildReqHeaders({ parentAppId, contentType: 'application/json' }),
  })

  if (!res.ok) {
    throw {
      response: {
        data: await parseResBody(res),
      },
    }
  }

  return res.json()
}

export default setPasswordAfterLogin
