import { parseResBody } from '../utils'
import CONSTANTS from './constants'
import UnexpectedError from './error/UnexpectedError'
import paths from './paths'
import buildReqHeaders from './utils/buildReqHeaders'

const getIdentityProviders = async ({
  accountName,
  scopeName,
  parentAppId,
}) => {
  const scope = scopeName === CONSTANTS.SCOPES.ADMIN ? '' : accountName

  const res = await fetch(paths.getIdentityProviders(accountName, scope), {
    headers: buildReqHeaders({ parentAppId }),
  })

  if (!res.ok) {
    throw {
      response: {
        data: await parseResBody(res),
      },
    }
  }

  if (res.status !== 200) {
    throw new UnexpectedError()
  }

  return res.json()
}

export default getIdentityProviders
