import React, { useContext } from 'react'

import { PageWrapperContext } from '../../PageWrapper'
import { NOOP } from '../constants'
import useHomeContext from '../homeContext/useHomeContext'
import useRedirectSaml from '../serviceHooks/vtexid/useRedirectSaml'
import CONSTANTS from '../services/vtexid/constants'

const RedirectSaml = ({
  onSuccess = NOOP,
  onFailure = NOOP,
  children,
  autorun,
  useNewSession = false,
  provider,
  email,
}) => {
  const { returnUrlQuery } = useContext(PageWrapperContext)
  const { handleSessionTimeout } = useHomeContext()

  const [redirectSaml, { loading }] = useRedirectSaml({
    onSuccess,
    onFailure,
    autorun,
    scope: CONSTANTS.SCOPES.ADMIN,
    loginAttempt: {
      onTimeout: handleSessionTimeout,
      returnUrl: returnUrlQuery,
    },
    actionArgs: {
      useNewLoginAttempt: useNewSession,
      provider,
      email,
    },
  })

  if (!children) return <></>

  return children({
    action: redirectSaml,
    loading,
  })
}

export default RedirectSaml
