import PropTypes from 'prop-types'
import React from 'react'

import useHomeContext from '../commons/homeContext/useHomeContext'
import TokenScreen from './TokenScreen'

const TokenLogin = ({
  onLoggedWithToken,
  onChangeEmail,
  onSwitchToPassword,
  setAlertError,
}) => {
  const { identityProviders } = useHomeContext()
  const { password } = identityProviders

  return (
    <div data-testid="token-login">
      <TokenScreen
        onTokenConfirmed={onLoggedWithToken}
        onChangeEmail={onChangeEmail}
        onSwitchToPassword={onSwitchToPassword}
        setAlertError={setAlertError}
        canSetPassword={password}
      />
    </div>
  )
}

TokenLogin.propTypes = {
  onLoggedWithToken: PropTypes.func.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  onSwitchToPassword: PropTypes.func.isRequired,
  setAlertError: PropTypes.func.isRequired,
}

export default TokenLogin
