import paths from './paths'

const redirectSaml = ({ provider }) => {
  if (!window || !window.location || !provider) {
    return
  }

  window.location.href = paths.getSamlRedirectUrl(provider)
}

export default redirectSaml
