import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Value } from 'react-values'
import { Button, ToastConsumer } from 'vtex.styleguide'

import { ERROR, RESEND_TIMER_SECONDS } from '../../commons/constants'
import useHomeContext from '../../commons/homeContext/useHomeContext'
import ResendMfaToken from '../../commons/tempAuthServices/ResendMfaToken'
import ValidateMfa from '../../commons/tempAuthServices/ValidateMfa'
import { TabbableInput } from '../shared'
import BorderlessButton from '../shared/BorderlessButton'
import TimerMessage from '../shared/TimerMessage'
import getIntlError from '../utils/getIntlError'
import useTimer from '../utils/useTimer'

const RequestSmsCode = ({ onSmsVerificationDone, setAlertError }) => {
  const [showTimer, setShowTimer] = useState(false)
  const hideTimer = useCallback(() => setShowTimer(false), [])
  const [startTimer, getSecsLeft] = useTimer({ onFinish: hideTimer })
  const { token, setToken } = useHomeContext()

  const handleResend = useCallback(
    (resend) => {
      if (getSecsLeft()) {
        setShowTimer(true)
      } else {
        resend()
      }
    },
    [getSecsLeft]
  )

  useEffect(() => startTimer(RESEND_TIMER_SECONDS), [startTimer])

  return (
    <Value defaultValue="">
      {({ value: errorMessage, set: setErrorMessage }) => (
        <ValidateMfa
          onSuccess={onSmsVerificationDone}
          onFailure={(error) => {
            if (error.code === 'WrongCredentials') {
              setErrorMessage(
                <FormattedMessage id="admin/mfaSetup.requestSmsCode.invalidMfaToken" />
              )
            } else {
              setAlertError(getIntlError(error.code))
            }
          }}>
          {({ action: validateMfa, loading }) => (
            <React.Fragment>
              <div>
                <div className="t-heading-4 c-on-base mb5">
                  <FormattedMessage id="admin/mfaSetup.requestSmsCode.title" />
                </div>
                <div className="t-body-ns t-small c-muted-1 lh-copy mb7">
                  <FormattedMessage id="admin/mfaSetup.requestSmsCode.text" />
                  <ToastConsumer>
                    {({ showToast }) => (
                      <ResendMfaToken
                        onFailure={({ code }) => {
                          if (code === ERROR.THROTTLED) {
                            setAlertError(
                              <FormattedMessage id="admin/error.ThrottledError" />
                            )

                            return
                          }

                          setAlertError(getIntlError(code))
                        }}
                        onSuccess={() => {
                          startTimer(RESEND_TIMER_SECONDS)
                          showToast({
                            message: (
                              <FormattedMessage id="admin/mfaSetup.requestSmsCode.smsCodeResent" />
                            ),
                          })
                        }}>
                        {({ action: resendCode, loading: _loading }) => (
                          <div className="mt5">
                            <BorderlessButton
                              onClick={() => handleResend(resendCode)}
                              loading={_loading}
                              disabled={showTimer}>
                              <span className="t-body-ns t-small">
                                <FormattedMessage id="admin/mfaSetup.requestSmsCode.resend" />
                              </span>
                            </BorderlessButton>
                          </div>
                        )}
                      </ResendMfaToken>
                    )}
                  </ToastConsumer>
                  {showTimer && (
                    <div className="mt3">
                      <TimerMessage getSecsLeft={getSecsLeft} />
                    </div>
                  )}
                </div>
                <div className="mb5" data-testid="mfa-input">
                  <TabbableInput
                    autoFocus
                    label={
                      <FormattedMessage id="admin/mfaSetup.requestSmsCode.code" />
                    }
                    size="large"
                    type="tel"
                    id="requestsmscode_code"
                    name="code"
                    value={token || ''}
                    onEnter={() => validateMfa({ mfaToken: token })}
                    onChange={(e) => setToken(e.target.value)}
                    errorMessage={errorMessage}
                  />
                </div>
                <div className="dib w-100 mb5">
                  <Button
                    id="requestsmscode_signinbtn"
                    variation="primary"
                    isLoading={loading}
                    block
                    onClick={() => validateMfa({ mfaToken: token })}>
                    <div data-testid="request-sms-continue">
                      <FormattedMessage id="admin/button.continue" />
                    </div>
                  </Button>
                </div>
              </div>
            </React.Fragment>
          )}
        </ValidateMfa>
      )}
    </Value>
  )
}

RequestSmsCode.propTypes = {
  onSmsVerificationDone: PropTypes.func.isRequired,
  setAlertError: PropTypes.func.isRequired,
}

export default RequestSmsCode
