import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { IconCaretRight } from 'vtex.styleguide'

import { ERROR, PASSWORD, TOKEN } from '../commons/constants'
import useHomeContext from '../commons/homeContext/useHomeContext'
import GetLoginPreference from '../commons/tempAuthServices/GetLoginPreference'
import SendAccessKey from '../commons/tempAuthServices/SendAccessKey'
import ExternalProviders from './ExternalProviders'
import FullPageLoading from './FullPageLoading'
import HorizontalSeparator from './shared/HorizontalSeparator'
import getIntlError from './utils/getIntlError'

const CachedUsers = ({
  onLogInWithToken,
  onLogInWithPassword,
  onFirstLogin,
  onNotMe,
  onManageUsers,
  onSamlLogin,
  onOauthLogin,
  setAlertError,
}) => {
  const [isLoading, setLoading] = useState(false)
  const { userAccounts, identityProviders, setEmail, recaptcha } =
    useHomeContext()

  const {
    googleOAuth,
    samlProviders,
    password: isProviderPasswordActive,
    accessKey: isProviderAccessKeyActive,
  } = identityProviders

  const hasNativeAndExternalLogins =
    (googleOAuth || samlProviders.length > 0) &&
    (isProviderPasswordActive || isProviderAccessKeyActive)

  const isProviderAccessKeyOnly =
    !isProviderPasswordActive && isProviderAccessKeyActive

  const isProviderPasswordOnly =
    isProviderPasswordActive && !isProviderAccessKeyActive

  const handleEnter = (callback) => (e) => {
    e.key === 'Enter' && callback()
  }

  return (
    <SendAccessKey
      onSuccess={({ preference }) => {
        const shouldLogInWithAccessKey =
          (isProviderAccessKeyActive && preference === TOKEN) ||
          isProviderAccessKeyOnly

        setLoading(false)
        if (shouldLogInWithAccessKey) {
          onLogInWithToken()
        } else {
          onFirstLogin()
        }
      }}
      onFailure={({ code }) => {
        setLoading(false)
        if (code === ERROR.THROTTLED) {
          setAlertError(<FormattedMessage id="admin/error.ThrottledError" />)

          return
        }

        setAlertError(getIntlError(code))
      }}>
      {({ action: sendToken }) => (
        <React.Fragment>
          <div className="t-heading-4 mb7 c-on-base">
            <FormattedMessage id="admin/savedAccounts.helloChooseAnAccount" />
          </div>
          <React.Fragment>{isLoading && <FullPageLoading />}</React.Fragment>
          <div className="mt5">
            {(isProviderPasswordActive || isProviderAccessKeyActive) && (
              <div className="mb4" data-testid="cached-users">
                <HorizontalSeparator />
                {userAccounts.map((email, i) => (
                  <div key={i} data-testid={`user-${i}`}>
                    <GetLoginPreference
                      onSuccess={(preference) => {
                        const shouldLogInWithPassword =
                          (isProviderPasswordActive &&
                            preference === PASSWORD) ||
                          isProviderPasswordOnly

                        setEmail(email)
                        if (shouldLogInWithPassword) {
                          onLogInWithPassword()
                        } else {
                          sendToken({
                            email,
                            preference,
                            recaptcha,
                            useNewLoginAttempt: true,
                          })
                        }
                      }}
                      onFailure={(error) => {
                        setLoading(false)
                        setAlertError(getIntlError(error.code))
                      }}>
                      {({ action: getLoginPreference }) => {
                        const handleLoadPreference = () => {
                          setLoading(true)
                          getLoginPreference({ email })
                        }

                        return (
                          <div
                            className="c-on-base h3 flex flex-column justify-center link pointer nh8 ph8 hover-bg-near-white"
                            tabIndex="0"
                            onKeyDown={handleEnter(handleLoadPreference)}
                            onClick={handleLoadPreference}>
                            <div className="flex justify-between">
                              {email}
                              <div className="h-100 flex flex-column justify-center c-action-primary">
                                <IconCaretRight size={14} />
                              </div>
                            </div>
                          </div>
                        )
                      }}
                    </GetLoginPreference>

                    <HorizontalSeparator />
                  </div>
                ))}
                <div
                  className="c-action-primary h3 flex flex-column justify-center link pointer nh8 ph8 hover-bg-near-white"
                  onKeyDown={handleEnter(onNotMe)}
                  tabIndex="0"
                  data-testid="use-another-account"
                  onClick={onNotMe}>
                  <div className="flex justify-between">
                    <FormattedMessage id="admin/savedAccounts.useAnotherAccount" />
                    <div className="h-100 flex flex-column justify-center">
                      <IconCaretRight size={14} />
                    </div>
                  </div>
                </div>
                <HorizontalSeparator />
              </div>
            )}
            <div className="dib w-100 flex flex-column">
              {hasNativeAndExternalLogins && (
                <div className="flex flex-row w-100 nowrap items-center center mv2">
                  <hr className="ma0 b--black-10 bb bb-0 w-50" />
                  <span className="dark-gray mh3 tt c-muted-1 t-small">
                    <FormattedMessage id="admin/divider.or" />
                  </span>
                  <hr className="ma0 b--black-10 bb bb-0 w-50" />
                </div>
              )}
              <ExternalProviders
                onSamlLogin={onSamlLogin}
                onOauthLogin={onOauthLogin}
                googleOAuth={googleOAuth}
                samlProviders={samlProviders}
                setAlertError={setAlertError}
              />
            </div>
            <div className="t-small tc mt3">
              <a
                className="c-action-primary link pointer"
                data-testid="remove-account"
                onClick={onManageUsers}>
                <FormattedMessage id="admin/savedAccounts.removeAccount" />
              </a>
            </div>
          </div>
        </React.Fragment>
      )}
    </SendAccessKey>
  )
}

CachedUsers.propTypes = {
  onLogInWithToken: PropTypes.func.isRequired,
  onLogInWithPassword: PropTypes.func.isRequired,
  onFirstLogin: PropTypes.func.isRequired,
  onNotMe: PropTypes.func.isRequired,
  onManageUsers: PropTypes.func.isRequired,
  onOauthLogin: PropTypes.func.isRequired,
  onSamlLogin: PropTypes.func.isRequired,
  setAlertError: PropTypes.func.isRequired,
}
export default CachedUsers
