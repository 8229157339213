import CONSTANTS from './constants'
import paths from './paths'
import buildReqHeaders from './utils/buildReqHeaders'

const reauthenticateUser = async ({ scopeName, parentAppId, fingerprint }) => {
  const audience = CONSTANTS.AUDIENCE[scopeName] || CONSTANTS.AUDIENCE.ADMIN

  const res = await fetch(paths.reauthenticateUser(audience), {
    method: 'POST',
    body: JSON.stringify({ fingerprint }),
    headers: buildReqHeaders({ parentAppId, contentType: 'application/json' }),
  })

  if (res.status !== 200) {
    return null
  }

  return res.json()
}

export default reauthenticateUser
