import { buildFormData, parseResBody } from '../utils'
import paths from './paths'
import buildReqHeaders from './utils/buildReqHeaders'

const setPassword = async ({
  login,
  newPassword,
  currentPassword,
  accesskey,
  recaptcha,
  parentAppId,
}) => {
  const body = buildFormData({
    login,
    newPassword,
    currentPassword,
    accesskey,
    recaptcha,
  })

  const res = await fetch(paths.setPassword(), {
    method: 'POST',
    body,
    headers: buildReqHeaders({ parentAppId }),
  })

  if (!res.ok) {
    throw {
      response: {
        data: await parseResBody(res),
      },
    }
  }

  return res.json()
}

export default setPassword
