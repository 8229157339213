import PropTypes from 'prop-types'
import React from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { Value } from 'react-values'
import { Button, InputPassword } from 'vtex.styleguide'

import useHomeContext from '../../commons/homeContext/useHomeContext'
import SetPasswordAfterLogin from '../../commons/tempAuthServices/SetPasswordAfterLogin'
import validatePassword from '../../commons/validations/validatePassword'
import { PasswordRequirement } from '../shared'
import getIntlError from '../utils/getIntlError'
import joinErrorMessages from '../utils/joinErrorMessages'
import useGetPasswordErrors from '../utils/useGetPasswordErrors'

const messages = defineMessages({
  separator: {
    id: 'admin/passwordLogin.separator',
    defaultMessage: '',
  },
  lastSeparator: {
    id: 'admin/passwordLogin.lastSeparator',
    defaultMessage: '',
  },
})

const SetPassword = ({ onPasswordSet, setAlertError }) => {
  const { email, password, setPassword, identityProviders } = useHomeContext()
  const getPasswordErrors = useGetPasswordErrors()
  const { formatMessage } = useIntl()
  const {
    password: isProviderPasswordActive,
    accessKey: isProviderAccessKeyActive,
  } = identityProviders

  const hasProviderAllActive =
    isProviderPasswordActive && isProviderAccessKeyActive

  return (
    <div data-testid="set-password">
      <div>
        <div className="t-heading-4 c-on-base mb5">
          <FormattedMessage id="admin/setPassword.createYourPassword" />
        </div>
        <div className="t-small t-body-ns c-on-base lh-copy">{email}</div>
      </div>
      <Value defaultValue="">
        {({
          value: errorMessage,
          set: setErrorMessage,
          reset: removeErrorMessage,
        }) => {
          const {
            passwordIsValid,
            hasMinLength,
            hasNumber,
            hasUpperCaseLetter,
            hasLowerCaseLetter,
            hasLetter,
          } = validatePassword(password || '')

          return (
            <div className="dib mt6 w-100" data-testid="password-input">
              <InputPassword
                name="password"
                id="forgotpass--pass"
                size="large"
                label={
                  <FormattedMessage id="admin/passwordLogin.inputLabel.password" />
                }
                value={password || ''}
                onChange={(e) => {
                  removeErrorMessage()
                  setPassword(e.target.value)
                }}
                errorMessage={errorMessage}
              />
              <div className="mv4">
                <SetPasswordAfterLogin
                  onSuccess={onPasswordSet}
                  onFailure={(error) =>
                    setAlertError(getIntlError(error.code))
                  }>
                  {({ loading, action: setPasswordAfterLogin }) => (
                    <Button
                      variation="primary"
                      block
                      isLoading={loading}
                      onClick={() => {
                        if (passwordIsValid) {
                          setPasswordAfterLogin({
                            password,
                            disableSetPreference: !hasProviderAllActive,
                          })
                        } else {
                          setErrorMessage(
                            <FormattedMessage
                              id="admin/passwordLogin.inlinePasswordError"
                              values={{
                                errors: joinErrorMessages(
                                  getPasswordErrors(password),
                                  formatMessage(messages.separator),
                                  formatMessage(messages.lastSeparator)
                                ),
                              }}
                            />
                          )
                        }
                      }}>
                      <div data-testid="create-password">
                        <FormattedMessage id="admin/setPassword.buttton.createPassword" />
                      </div>
                    </Button>
                  )}
                </SetPasswordAfterLogin>
              </div>
              <PasswordRequirement
                hasEightCharacters={hasMinLength}
                hasNumber={hasNumber}
                hasUppercaseLetter={hasUpperCaseLetter}
                hasLowercaseLetter={hasLowerCaseLetter}
                hasLetter={hasLetter}
              />
            </div>
          )
        }}
      </Value>
    </div>
  )
}

SetPassword.propTypes = {
  onPasswordSet: PropTypes.func.isRequired,
  setAlertError: PropTypes.func.isRequired,
}

export default SetPassword
