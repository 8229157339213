import { NOOP } from '../../constants'
import CONSTANTS from '../../services/vtexid/constants'
import serviceRegisterMfaPhoneNumber from '../../services/vtexid/registerMfaPhoneNumber'
import useAsyncCallback from '../../services/vtexid/utils/useAsyncCallback'
import useBeginMfaSetupAttempt from './useBeginMfaSetupAttempt'

const { API_AUTH_STATUS } = CONSTANTS

const useRegisterMfaPhone = ({
  onSuccess = NOOP,
  onFailure = NOOP,
  autorun = false,
  parentAppId = null,
  actionArgs: {
    phoneNumber: phoneNumberArg = null,
    useNewMfaSetupAttempt: useNewMfaSetupAttemptArg = false,
  } = {},
  mfaSetupAttempt: { onTimeout: onMfaSetupAttemptTimeout = NOOP } = {},
} = {}) => {
  const beginMfaSetupAttempt = useBeginMfaSetupAttempt(
    onMfaSetupAttemptTimeout,
    [],
    { parentAppId }
  )

  const [registerMfaPhoneNumber, { error, loading }] = useAsyncCallback(
    async ({
      phoneNumber = phoneNumberArg,
      useNewMfaSetupAttempt = useNewMfaSetupAttemptArg,
    } = {}) => {
      if (useNewMfaSetupAttempt) {
        await beginMfaSetupAttempt()
      }

      const { authStatus } = await serviceRegisterMfaPhoneNumber({
        phoneNumber,
        parentAppId,
      })

      if (authStatus !== API_AUTH_STATUS.Pending) {
        throw { authStatus }
      }
    },
    [
      phoneNumberArg,
      useNewMfaSetupAttemptArg,
      parentAppId,
      beginMfaSetupAttempt,
    ],
    { onSuccess, onFailure, autorun }
  )

  return [
    registerMfaPhoneNumber,
    {
      error,
      loading,
    },
  ]
}

export default useRegisterMfaPhone
