import { buildFormData, parseResBody } from '../utils'
import buildReqHeaders from './utils/buildReqHeaders'
import BlockedUserError from './error/BlockedUserError'
import ThrottledError from './error/ThrottledError'
import paths from './paths'

const validateToken = async ({
  login,
  accesskey,
  recaptcha,
  recaptchaToken,
  setPreference,
  parentAppId,
}) => {
  const body = buildFormData({
    login,
    accesskey,
    recaptcha,
    recaptchaToken,
  })

  const res = await fetch(paths.validateToken(setPreference), {
    method: 'POST',
    body,
    headers: buildReqHeaders({ parentAppId }),
  })

  if (res.status === 429) {
    throw new ThrottledError()
  }

  if (res.status === 401) {
    throw new BlockedUserError()
  }

  if (!res.ok) {
    throw {
      response: {
        statusCode: res.status,
        data: await parseResBody(res),
      },
    }
  }

  return res.json()
}

export default validateToken
