import PropTypes from 'prop-types'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { Button, Input, InputPassword, ToastContext } from 'vtex.styleguide'

import { ERROR, RESEND_TIMER_SECONDS } from '../../commons/constants'
import useHomeContext from '../../commons/homeContext/useHomeContext'
import SendAccessKey from '../../commons/tempAuthServices/SendAccessKey'
import SetPassword from '../../commons/tempAuthServices/SetPassword'
import validatePassword from '../../commons/validations/validatePassword'
import { BorderlessButton, PasswordRequirement, TimerMessage } from '../shared'
import getIntlError from '../utils/getIntlError'
import joinErrorMessages from '../utils/joinErrorMessages'
import useGetPasswordErrors from '../utils/useGetPasswordErrors'
import useTimer from '../utils/useTimer'

const messages = defineMessages({
  separator: {
    id: 'admin/passwordLogin.separator',
    defaultMessage: '',
  },
  lastSeparator: {
    id: 'admin/passwordLogin.lastSeparator',
    defaultMessage: '',
  },
})

const SetForgotPassword = ({ onChangeEmail, onPasswordSet, setAlertError }) => {
  const {
    email,
    password,
    setPassword: setPasswordState,
    token,
    setToken,
    currentPassword,
    recaptcha,
  } = useHomeContext()

  const { showToast } = useContext(ToastContext)
  const getPasswordErrors = useGetPasswordErrors()
  const { formatMessage } = useIntl()

  const [errorMessage, setErrorMessage] = useState('')
  const [showTimer, setShowTimer] = useState(false)
  const hideTimer = useCallback(() => setShowTimer(false), [])
  const [startTimer, getSecsLeft] = useTimer({ onFinish: hideTimer })

  const {
    passwordIsValid,
    hasMinLength,
    hasNumber,
    hasUpperCaseLetter,
    hasLowerCaseLetter,
    hasLetter,
  } = validatePassword(password || '')

  const handleResend = useCallback(
    (resend) => {
      if (getSecsLeft()) {
        setShowTimer(true)
      } else {
        resend()
      }
    },
    [getSecsLeft]
  )

  useEffect(() => startTimer(RESEND_TIMER_SECONDS), [startTimer])

  return (
    <div data-testid="set-forgot-password">
      {/* Initial texts */}
      <div>
        <div className="t-heading-4 c-on-base mb5">
          <FormattedMessage id="admin/tokenConfirmation.codeSentByEmail" />
        </div>
        <div className="t-body-ns t-small c-muted-1 lh-copy">
          <FormattedMessage id="admin/tokenConfirmation.insertNumbers" />{' '}
          <div className="serious-black dib">{email}</div>
        </div>
      </div>
      <div className="flex pb3 mt5 t-small">
        {/* AccessKey Resend */}
        <SendAccessKey
          onSuccess={() => {
            showToast({
              message: (
                <FormattedMessage id="admin/tokenConfirmation.tokenResent" />
              ),
            })
            setAlertError('')
            startTimer(RESEND_TIMER_SECONDS)
          }}
          onFailure={({ code }) => {
            if (code === ERROR.THROTTLED) {
              setAlertError(
                <FormattedMessage id="admin/error.ThrottledError" />
              )

              return
            }

            setAlertError(
              <FormattedMessage id="admin/tokenConfirmation.resendTokenError" />
            )
          }}>
          {({ loading, action: sendToken }) => (
            <span className="dark-gray">
              <BorderlessButton
                onClick={() =>
                  handleResend(() =>
                    sendToken({
                      email,
                      recaptcha,
                    })
                  )
                }
                loading={loading}
                disabled={showTimer}>
                <FormattedMessage id="admin/tokenConfirmation.resendCode" />
              </BorderlessButton>
            </span>
          )}
        </SendAccessKey>
        {/* Change email */}
        <div className="ml6">
          <BorderlessButton onClick={onChangeEmail}>
            <span data-testid="change-email" className="t-small">
              <FormattedMessage id="admin/tokenConfirmation.changeEmail" />
            </span>
          </BorderlessButton>
        </div>
      </div>
      {showTimer && <TimerMessage getSecsLeft={getSecsLeft} />}
      <div className="mt4">
        {/* Token */}
        <div data-testid="token-input">
          <Input
            token
            type="text"
            pattern="\d*"
            size="large"
            label={
              <FormattedMessage id="admin/tokenConfirmation.inputLabel.code" />
            }
            value={token || ''}
            onChange={(e) => {
              setToken(e.target.value)
              setErrorMessage('')
            }}
          />
        </div>
        {/* Password Input */}
        <div className="mt5" data-testid="password-input">
          <InputPassword
            name="password"
            id="forgotpass--pass"
            size="large"
            label={
              <FormattedMessage id="admin/udpatePassword.inputLabel.password" />
            }
            value={password || ''}
            onChange={(e) => {
              setErrorMessage('')
              setPasswordState(e.target.value)
            }}
            errorMessage={errorMessage}
          />
        </div>
        {/* Change Password Button */}
        <div className="mv4">
          <SetPassword
            onSuccess={onPasswordSet}
            onFailure={(error) => {
              if (error.code == 'WrongCredentials') {
                setAlertError(
                  <FormattedMessage id="admin/tokenConfirmation.incorrectTokenError" />
                )

                return
              }

              setAlertError(getIntlError(error.code))
            }}>
            {({ loading, action: setPassword }) => (
              <Button
                variation="primary"
                block
                isLoading={loading}
                onClick={() => {
                  if (passwordIsValid) {
                    setPassword({
                      newPassword: password,
                      token,
                      currentPassword,
                      recaptcha,
                      email,
                    })
                  } else {
                    setErrorMessage(
                      <FormattedMessage
                        id="admin/passwordLogin.inlinePasswordError"
                        values={{
                          errors: joinErrorMessages(
                            getPasswordErrors(password),
                            formatMessage(messages.separator),
                            formatMessage(messages.lastSeparator)
                          ),
                        }}
                      />
                    )
                  }
                }}>
                <div data-testid="create-password">
                  <FormattedMessage id="admin/updatePassword.buttton.createPassword" />
                </div>
              </Button>
            )}
          </SetPassword>
        </div>
        <PasswordRequirement
          hasEightCharacters={hasMinLength}
          hasNumber={hasNumber}
          hasUppercaseLetter={hasUpperCaseLetter}
          hasLowercaseLetter={hasLowerCaseLetter}
          hasLetter={hasLetter}
        />
      </div>
    </div>
  )
}

SetForgotPassword.propTypes = {
  onPasswordSet: PropTypes.func.isRequired,
  setAlertError: PropTypes.func.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
}

export default SetForgotPassword
