import type { Dispatch, SetStateAction } from 'react'
import { createContext } from 'react'

type OAuthProvider = {
  providerName: string
  className: string
  expectedContext: unknown
}

type SamlProvider = {
  id: string
  idpUrl: string
  idpName: string
}

type CorporateProvider = {
  account: string
}

export type IdentityProviders = {
  oAuthProviders: OAuthProvider[]
  samlProviders: SamlProvider[]
  password: boolean
  accessKey: boolean
  passkey: boolean
  corporateProviders: CorporateProvider[]
}

export type ContextValues = {
  userAccounts: string[]
  setUserAccounts: Dispatch<SetStateAction<string[]>>
  email: string | null
  setEmail: Dispatch<SetStateAction<string | null>>
  password: string
  setPassword: Dispatch<SetStateAction<string>>
  currentPassword: string | null
  setCurrentPassword: Dispatch<SetStateAction<string | null>>
  phoneNumber: string | null
  setPhoneNumber: Dispatch<SetStateAction<string | null>>
  token: string | null
  setToken: Dispatch<string>
  recaptcha: string | null
  setRecaptcha: Dispatch<SetStateAction<string | null>>
  identityProviders: IdentityProviders
  isSessionExpired: boolean
  handleSessionTimeout: Dispatch<void>
}

const context = createContext<ContextValues | null>(null)

export default context
