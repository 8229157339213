import React, { useCallback, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { AuthState } from 'vtex.react-vtexid'

import { SELF_APP_NAME_AND_VERSION } from './commons/constants'
import HomeProvider from './commons/homeContext/Provider'
import useHomeContext from './commons/homeContext/useHomeContext'
import LoadingWithMessage from './components/LoadingWithMessage'
import SessionExpired from './components/SessionExpired'
import StateMachine from './components/stateMachine'
import transitionsMapping from './components/stateMachine/transitionsMapping'
import UnexpectedError from './components/UnexpectedError'
import componentByState from './components/utils/componentByState'
import { PageWrapperContext } from './PageWrapper'

const Home = ({ identityProviders, isUserAuthenticated, userId }) => {
  const { handleErrorMessage, handleClearErrorMessage } =
    useContext(PageWrapperContext)

  const { userAccounts, setToken } = useHomeContext()

  const handleStateChange = useCallback(() => {
    handleClearErrorMessage()
    setToken('')
  }, [handleClearErrorMessage, setToken])

  return (
    <StateMachine
      transitionsMapping={transitionsMapping}
      identityProviders={identityProviders}
      isUserAuthenticated={isUserAuthenticated}
      isUserIdentified={userAccounts.length !== 0}
      onError={handleErrorMessage}
      onClearError={handleStateChange}
      onStateChange={handleClearErrorMessage}>
      {({ state, transitionHandlers }) => {
        const StateComponent = componentByState[state]
        const props = {
          ...transitionHandlers,
          userId,
          setAlertError: handleErrorMessage,
        }

        return <StateComponent {...props} />
      }}
    </StateMachine>
  )
}

const HomeWrapped = () => {
  const { emailQuery, returnUrlQuery } = useContext(PageWrapperContext)

  return (
    <AuthState
      skip
      returnUrl={returnUrlQuery}
      email={emailQuery}
      scope="ADMIN"
      parentAppId={SELF_APP_NAME_AND_VERSION}>
      <HomeProvider
        email={emailQuery}
        scope="ADMIN"
        parentAppId={SELF_APP_NAME_AND_VERSION}>
        {({
          loading,
          error,
          identityProviders,
          isUserAuthenticated,
          userId,
          isSessionExpired,
        }) => {
          if (isSessionExpired) {
            return <SessionExpired />
          }

          if (error) {
            return <UnexpectedError />
          }

          if (loading) {
            return (
              <LoadingWithMessage
                topMessage={
                  <FormattedMessage id="admin/loadingSpinner.welcome" />
                }
              />
            )
          }

          return (
            <div data-testid="state-component">
              <Home
                identityProviders={identityProviders}
                isUserAuthenticated={isUserAuthenticated}
                userId={userId}
              />
            </div>
          )
        }}
      </HomeProvider>
    </AuthState>
  )
}

export default HomeWrapped
