import useSetPasswordAfterLogin from '../serviceHooks/vtexid/useSetPasswordAfterLogin'
import CONSTANTS from '../services/vtexid/constants'

const SetPasswordAfterLogin = ({ onSuccess, onFailure, children }) => {
  const [setPasswordAfterLogin, { loading }] = useSetPasswordAfterLogin({
    onSuccess,
    onFailure,
    scope: CONSTANTS.SCOPES.ADMIN,
  })

  return children({
    action: setPasswordAfterLogin,
    loading,
  })
}

export default SetPasswordAfterLogin
