import { useContext } from 'react'

import { PageWrapperContext } from '../../PageWrapper'
import useHomeContext from '../homeContext/useHomeContext'
import useSendAccessKey from '../serviceHooks/vtexid/useSendAccessKey'
import CONSTANTS from '../services/vtexid/constants'

const SendAccessKey = ({ onSuccess, onFailure, children }) => {
  const { returnUrlQuery } = useContext(PageWrapperContext)
  const { handleSessionTimeout } = useHomeContext()

  const [sendAccessKey, { loading }] = useSendAccessKey({
    onSuccess,
    onFailure,
    scope: CONSTANTS.SCOPES.ADMIN,
    loginAttempt: {
      onTimeout: handleSessionTimeout,
      returnUrl: returnUrlQuery,
    },
  })

  return children({
    action: sendAccessKey,
    loading,
  })
}

export default SendAccessKey
