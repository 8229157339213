import { useContext } from 'react'

import { PageWrapperContext } from '../../PageWrapper'
import { NOOP } from '../constants'
import useHomeContext from '../homeContext/useHomeContext'
import useOAuthRedirect from '../serviceHooks/vtexid/useOAuthRedirect'
import CONSTANTS from '../services/vtexid/constants'

const OAuthRedirect = ({
  children,
  provider,
  onSuccess = NOOP,
  onFailure = NOOP,
  autorun = false,
  useNewSession = false,
  errorFallbackUrl = null,
  email = null,
}) => {
  const { returnUrlQuery } = useContext(PageWrapperContext)
  const { handleSessionTimeout } = useHomeContext()

  const [redirectToOAuthPage, { loading }] = useOAuthRedirect({
    autorun,
    actionArgs: {
      useNewLoginAttempt: useNewSession,
      provider,
      errorFallbackUrl,
      email,
    },
    onSuccess,
    onFailure,
    scope: CONSTANTS.SCOPES.ADMIN,
    loginAttempt: {
      onTimeout: handleSessionTimeout,
      returnUrl: returnUrlQuery,
    },
  })

  return children({
    action: redirectToOAuthPage,
    loading,
  })
}

export default OAuthRedirect
