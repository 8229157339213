import { useMemo, useState } from 'react'

import useSetStateWrapper from './useSetStateWrapper'
import {
  getUserAccountsFromCookie,
  setCookieWithUserAccounts,
} from './userAccountsCookie'

const useUserAccounts = () => {
  const initialUserAccounts = useMemo(() => getUserAccountsFromCookie(), [])
  const [userAccounts, setUserAccounts] = useState(initialUserAccounts)

  const setUserAccountsAndCookie = useSetStateWrapper(
    newUserAccounts => setCookieWithUserAccounts(newUserAccounts),
    [],
    setUserAccounts
  )

  return [userAccounts, setUserAccountsAndCookie]
}

export default useUserAccounts
