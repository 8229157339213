import PropTypes from 'prop-types'
import React, { useContext, useMemo, useState } from 'react'
import Recaptcha from 'react-google-recaptcha'
import { FormattedMessage } from 'react-intl'
import { Button } from 'vtex.styleguide'

import {
  ERROR,
  PASSWORD,
  RECAPTCHA_SITEKEY,
  TOKEN,
} from '../../commons/constants'
import useHomeContext from '../../commons/homeContext/useHomeContext'
import GetLoginPreference from '../../commons/tempAuthServices/GetLoginPreference'
import SendAccessKey from '../../commons/tempAuthServices/SendAccessKey'
import validateEmail from '../../commons/validations/validateEmail'
import { PageWrapperContext } from '../../PageWrapper'
import EmailInput from '../EmailInput'
import getIntlError from '../utils/getIntlError'

const recaptchaRef = React.createRef()

const EmailForm = ({
  onLogInWithToken,
  onLogInWithPassword,
  onFirstLogin,
  setAlertError,
  isProviderPasswordActive,
  isProviderAccessKeyActive,
}) => {
  const { email, setEmail, recaptcha, setRecaptcha } = useHomeContext()

  const { handleIsUnauthorized } = useContext(PageWrapperContext)
  const [hasRecaptchaError, setRecaptchaError] = useState(false)
  const [isRecaptchaVisible, setRecaptchaVisibility] = useState(false)
  const [isSubmittingForm, setSubmittingForm] = useState(false)
  const [hasError, setErrorVisibility] = useState(false)

  const isProviderAccessKeyOnly = useMemo(
    () => !isProviderPasswordActive && isProviderAccessKeyActive,
    [isProviderPasswordActive, isProviderAccessKeyActive]
  )

  const isProviderPasswordOnly = useMemo(
    () => isProviderPasswordActive && !isProviderAccessKeyActive,
    [isProviderPasswordActive, isProviderAccessKeyActive]
  )

  const isEmailValid = useMemo(() => validateEmail(email || ''), [email])

  return (
    <SendAccessKey
      onFailure={({ code }) => {
        if (recaptchaRef && recaptchaRef.current) {
          recaptchaRef.current.reset()
        }

        if (code === ERROR.THROTTLED) {
          setAlertError(<FormattedMessage id="admin/error.ThrottledError" />)

          return
        }

        if (['BlockedUser', 'InvalidRecaptcha'].includes(code)) {
          if (isRecaptchaVisible) {
            setRecaptchaError(true)
          } else {
            setRecaptchaVisibility(true)
          }

          return
        }

        setAlertError(getIntlError(code))
      }}
      onSuccess={({ preference }) => {
        const shouldLogInWithAccessKey =
          (preference === TOKEN && isProviderAccessKeyActive) ||
          isProviderAccessKeyOnly

        if (shouldLogInWithAccessKey) {
          onLogInWithToken()
        } else {
          onFirstLogin()
        }
      }}>
      {({ loading: sendingToken, action: sendToken }) => {
        const submit = async (preference) => {
          const shouldLogInWithPassword =
            (preference === PASSWORD && isProviderPasswordActive) ||
            isProviderPasswordOnly

          if (preference && shouldLogInWithPassword) {
            onLogInWithPassword()
          } else if (preference) {
            setSubmittingForm(false)
            sendToken({
              email,
              preference,
              recaptcha,
              useNewLoginAttempt: true,
            })
          }
        }

        return (
          <GetLoginPreference
            onSuccess={(preference) => {
              submit(preference)
            }}
            onFailure={(error) => {
              setSubmittingForm(false)
              setAlertError(getIntlError(error.code))
            }}>
            {({ action: fetchLoginPreference }) => {
              const handleSubmit = () => {
                handleIsUnauthorized(false)
                if (!isEmailValid) {
                  setErrorVisibility(true)
                } else {
                  setSubmittingForm(true)
                  fetchLoginPreference({
                    email,
                  })
                }
              }

              const getInputError = (_hasError, _email) => {
                if (_hasError && !_email) {
                  return (
                    <FormattedMessage id="admin/emailError.emailIsRequired" />
                  )
                }

                if (_hasError && !isEmailValid) {
                  return (
                    <FormattedMessage id="admin/emailError.emailIsInvalid" />
                  )
                }

                return null
              }

              const inputErrorMessage = getInputError(hasError, email)

              return (
                <div className="mt5 mb4" data-testid="request-email">
                  <EmailInput
                    label={
                      <FormattedMessage id="admin/emailInputLabel.email" />
                    }
                    onChangeEmail={setEmail}
                    email={email}
                    onSubmitEmail={handleSubmit}
                    setShowError={setErrorVisibility}
                    errorMessage={inputErrorMessage}
                  />
                  {isRecaptchaVisible && (
                    <div className="mb5">
                      <div className="mb5 mt6 t-body-ns t-small c-on-base">
                        <FormattedMessage id="admin/recaptcha.explanation" />
                      </div>
                      <Recaptcha
                        sitekey={RECAPTCHA_SITEKEY}
                        ref={recaptchaRef}
                        onChange={(newRecaptcha) => {
                          setRecaptchaError(false)
                          setRecaptcha(newRecaptcha)
                        }}
                      />
                      {hasRecaptchaError && (
                        <div className="c-danger t-small mt3 lh-title">
                          <FormattedMessage id="admin/passwordLogin.recaptchaMissing" />
                        </div>
                      )}
                    </div>
                  )}
                  <Button
                    variation="primary"
                    block
                    isLoading={isSubmittingForm || sendingToken}
                    onClick={handleSubmit}>
                    <div data-testid="email-form-continue">
                      <FormattedMessage id="admin/button.continue" />
                    </div>
                  </Button>
                </div>
              )
            }}
          </GetLoginPreference>
        )
      }}
    </SendAccessKey>
  )
}

EmailForm.propTypes = {
  onLogInWithToken: PropTypes.func.isRequired,
  onLogInWithPassword: PropTypes.func.isRequired,
  onFirstLogin: PropTypes.func.isRequired,
  setAlertError: PropTypes.func.isRequired,
  isProviderPasswordActive: PropTypes.bool,
  isProviderAccessKeyActive: PropTypes.bool,
}

export default EmailForm
