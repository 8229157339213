import { useCallback } from 'react'

const useSetStateWrapper = (callback, depsLst, setState) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCallback = useCallback(callback, depsLst)

  return useCallback(
    obtainableNewState =>
      setState(currentState => {
        const newState =
          typeof obtainableNewState === 'function'
            ? obtainableNewState(currentState)
            : obtainableNewState

        memoizedCallback(newState)

        return newState
      }),
    [memoizedCallback, setState]
  )
}

export default useSetStateWrapper
