import PropTypes from 'prop-types'
import React from 'react'
import Recaptcha from 'react-google-recaptcha'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { BooleanValue, Value } from 'react-values'
import { Button, Spinner } from 'vtex.styleguide'

import { RECAPTCHA_SITEKEY } from '../commons/constants'
import useHomeContext from '../commons/homeContext/useHomeContext'
import LogInWithPassword from '../commons/tempAuthServices/LogInWithPassword'
import SendAccessKey from '../commons/tempAuthServices/SendAccessKey'
import validatePassword from '../commons/validations/validatePassword'
import { TabbableInput } from './shared'
import getIntlError from './utils/getIntlError'
import joinErrorMessages from './utils/joinErrorMessages'
import useGetPasswordErrors from './utils/useGetPasswordErrors'

const messages = defineMessages({
  separator: {
    id: 'admin/passwordLogin.separator',
    defaultMessage: '',
  },
  lastSeparator: {
    id: 'admin/passwordLogin.lastSeparator',
    defaultMessage: '',
  },
})

const recaptchaRef = React.createRef()

const RequestPassword = ({
  onLoggedWithPassword,
  onPasswordExpired,
  onForgotPassword,
  onTwoFactorRequired,
  onBack,
  setAlertError,
}) => {
  const {
    email,
    password,
    setPassword,
    setPhoneNumber,
    setCurrentPassword,
    recaptcha,
    setRecaptcha,
  } = useHomeContext()

  const { formatMessage } = useIntl()
  const getPasswordErrors = useGetPasswordErrors()

  return (
    <div data-testid="password-login">
      <div className="mb7">
        <div className="t-heading-4 c-on-base mb5">
          <FormattedMessage id="admin/passwordLogin.enterPassword" />
        </div>
        <div className="t-body-ns t-small c-on-base">{email}</div>
        <div className="flex mb4 mt5 t-small">
          <a
            data-testid="back"
            className="link c-link pointer t-small"
            onClick={onBack}>
            <FormattedMessage id="admin/tokenConfirmation.changeEmail" />
          </a>
        </div>
      </div>
      <BooleanValue>
        {({ value: hasRecaptchaError, set: setRecaptchaError }) => (
          <BooleanValue>
            {({ value: isRecaptchaVisible, set: setRecaptchaVisibility }) => (
              <Value defaultValue="">
                {({
                  value: errorMessage,
                  set: setErrorMessage,
                  reset: removeErrorMessage,
                }) => {
                  const { passwordIsValid } = validatePassword(password || '')

                  return (
                    <LogInWithPassword
                      saveUserAccount
                      onSuccess={onLoggedWithPassword}
                      onRequiresPasswordUpdate={() => {
                        setCurrentPassword(password)
                        setPassword('')
                        onPasswordExpired()
                      }}
                      onRequiresSmsMfa={({ phoneNumber }) => {
                        setPhoneNumber(phoneNumber)
                        onTwoFactorRequired({
                          details: {
                            userHasTwoFactor: true,
                            twoFactorType: 'SMS',
                          },
                        })
                      }}
                      onRequiresAppMfa={() =>
                        onTwoFactorRequired({
                          details: {
                            userHasTwoFactor: true,
                            twoFactorType: 'AUTH_APP',
                          },
                        })
                      }
                      onRequiresMfaRegistration={() =>
                        onTwoFactorRequired({
                          details: {
                            userHasTwoFactor: false,
                          },
                        })
                      }
                      onFailure={(error) => {
                        if (recaptchaRef && recaptchaRef.current) {
                          recaptchaRef.current.reset()
                        }

                        if (error.code === 'WrongCredentials') {
                          setErrorMessage(
                            <FormattedMessage id="admin/passwordLogin.incorrectPassword" />
                          )
                        } else if (
                          ['BlockedUser', 'InvalidRecaptcha'].includes(
                            error.code
                          )
                        ) {
                          if (isRecaptchaVisible) {
                            setRecaptchaError(true)
                          } else {
                            setRecaptchaVisibility(true)
                          }
                        } else {
                          setAlertError(getIntlError(error.code))
                        }
                      }}
                      email={email}>
                      {({ loading, action: loginWithPassword }) => {
                        const tryLogin = () => {
                          setRecaptchaError(false)
                          removeErrorMessage()
                          if (password && passwordIsValid) {
                            setRecaptcha('')
                            loginWithPassword({
                              email,
                              password,
                              recaptcha,
                              useNewLoginAttempt: true,
                            })
                          } else {
                            setErrorMessage(
                              <FormattedMessage
                                id="admin/passwordLogin.inlinePasswordError"
                                values={{
                                  errors: joinErrorMessages(
                                    getPasswordErrors(password),
                                    formatMessage(messages.separator),
                                    formatMessage(messages.lastSeparator)
                                  ),
                                }}
                              />
                            )
                          }
                        }

                        return (
                          <React.Fragment>
                            <div className="mb5" data-testid="password-input">
                              <TabbableInput
                                autoFocus
                                size="large"
                                type="password"
                                label={
                                  <FormattedMessage id="admin/passwordLogin.inputLabel.password" />
                                }
                                name="password"
                                value={password || ''}
                                onChange={(e) => {
                                  removeErrorMessage()
                                  setPassword(e.target.value)
                                }}
                                onTab={tryLogin}
                                onEnter={tryLogin}
                                errorMessage={errorMessage}
                              />
                            </div>
                            <div>
                              {isRecaptchaVisible && (
                                <div className="mb5">
                                  <div className="mb5 mt3 t-body-ns t-small c-on-base">
                                    <FormattedMessage id="admin/recaptcha.explanation" />
                                  </div>
                                  <Recaptcha
                                    sitekey={RECAPTCHA_SITEKEY}
                                    ref={recaptchaRef}
                                    onChange={(newRecaptcha) => {
                                      setRecaptchaError(false)
                                      setRecaptcha(newRecaptcha)
                                    }}
                                  />
                                  {hasRecaptchaError && (
                                    <div className="c-danger t-small mt3 lh-title">
                                      <FormattedMessage id="admin/passwordLogin.recaptchaMissing" />
                                    </div>
                                  )}
                                </div>
                              )}
                              <Button
                                id="chooseprovider_signinbtn"
                                variation="primary"
                                block
                                isLoading={loading}
                                onClick={tryLogin}>
                                <div data-testid="request-password-continue">
                                  <FormattedMessage id="admin/button.continue" />
                                </div>
                              </Button>
                            </div>
                            <div className="dib w-100 mt6 mb7 flex justify-center">
                              <SendAccessKey
                                onSuccess={onForgotPassword}
                                onFailure={() =>
                                  setAlertError(
                                    <FormattedMessage id="admin/passwordLogin.sendTokenError" />
                                  )
                                }>
                                {({
                                  loading: loadingSendAccessKey,
                                  action: sendToken,
                                }) => {
                                  if (loadingSendAccessKey) {
                                    return <Spinner size={16} />
                                  }

                                  return (
                                    <a
                                      data-testid="forgot-password"
                                      onClick={() =>
                                        sendToken({
                                          email,
                                          recaptcha,
                                          useNewLoginAttempt: true,
                                        })
                                      }
                                      className="fr link c-link f6 pointer">
                                      <FormattedMessage id="admin/passwordLogin.forgotPassword" />
                                    </a>
                                  )
                                }}
                              </SendAccessKey>
                            </div>
                          </React.Fragment>
                        )
                      }}
                    </LogInWithPassword>
                  )
                }}
              </Value>
            )}
          </BooleanValue>
        )}
      </BooleanValue>
    </div>
  )
}

RequestPassword.propTypes = {
  onLoggedWithPassword: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onPasswordExpired: PropTypes.func.isRequired,
  onForgotPassword: PropTypes.func.isRequired,
  onTwoFactorRequired: PropTypes.func.isRequired,
  setAlertError: PropTypes.func.isRequired,
}

export default RequestPassword
