import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from 'vtex.styleguide'

import { ERROR } from '../../commons/constants'
import useHomeContext from '../../commons/homeContext/useHomeContext'
import RegisterMfaPhone from '../../commons/tempAuthServices/RegisterMfaPhone'
import PhoneInput from '../shared/PhoneInput'
import getIntlError from '../utils/getIntlError'

const RequestPhone = ({ setAlertError, onSmsSetUp }) => {
  const [countryCode, setCountryCode] = useState('')
  const [phone, setPhone] = useState('')
  const [errorCountryCode, setErrorCountryCode] = useState(false)
  const [errorPhone, setErrorPhone] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [continueDisabled, setContinueDisabled] = useState(false)
  const { phoneNumber, setPhoneNumber } = useHomeContext()

  const handleClickContinue = useCallback(
    ({ registerPhone }) => {
      if (!countryCode) {
        setErrorCountryCode(true)
      }

      if (!phone) {
        setErrorPhone(true)
      }

      if (!phone || !countryCode) {
        setErrorMessage(
          <FormattedMessage id="admin/mfaSetup.requestPhone.required_field" />
        )
        setContinueDisabled(true)
      } else {
        registerPhone &&
          registerPhone({
            phoneNumber,
          })
      }
    },
    [countryCode, phone, phoneNumber]
  )

  const cleanErrors = useCallback(() => {
    setErrorCountryCode(false)
    setErrorPhone(false)
    setErrorMessage('')
    setContinueDisabled(false)
  }, [])

  return (
    <RegisterMfaPhone
      onFailure={({ code }) => {
        if (code === ERROR.THROTTLED) {
          setAlertError(<FormattedMessage id="admin/error.ThrottledError" />)

          return
        }

        setAlertError(getIntlError(code))
      }}
      onSuccess={onSmsSetUp}>
      {({ action: registerPhone, loading }) => (
        <React.Fragment>
          <div>
            <div className="t-heading-4 c-on-base mb5">
              <FormattedMessage id="admin/mfaSetup.requestPhone.title" />
            </div>
            <div className="t-body-ns t-small c-muted-1 lh-copy mb7">
              <FormattedMessage id="admin/mfaSetup.requestPhone.text" />
            </div>
            <div className="mb5">
              <PhoneInput
                label={
                  <FormattedMessage id="admin/mfaSetup.requestPhone.phone" />
                }
                errorCountryCode={errorCountryCode}
                errorPhone={errorPhone}
                errorMessage={errorMessage}
                valueCountryCode={countryCode}
                onChangeCountryCode={(newCountryCode) => {
                  setCountryCode(newCountryCode)
                  setPhoneNumber(`+${newCountryCode}${phone}`)
                  cleanErrors()
                }}
                valuePhone={phone}
                onChangePhone={(newPhone) => {
                  setPhone(newPhone)
                  setPhoneNumber(`+${countryCode}${newPhone}`)
                  cleanErrors()
                }}
                onEnter={() => handleClickContinue({ registerPhone })}
              />
            </div>
            <div className="dib w-100 mb5">
              <Button
                disabled={continueDisabled}
                isLoading={loading}
                id="requestphone_signinbtn"
                variation="primary"
                block
                onClick={() => handleClickContinue({ registerPhone })}>
                <div data-testid="request-phone-continue">
                  <FormattedMessage id="admin/button.continue" />
                </div>
              </Button>
            </div>
          </div>
        </React.Fragment>
      )}
    </RegisterMfaPhone>
  )
}

RequestPhone.propTypes = {
  setAlertError: PropTypes.func.isRequired,
  onSmsSetUp: PropTypes.func.isRequired,
}

export default RequestPhone
